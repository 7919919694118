import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from 'tss-react/mui';
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import Paper from "@mui/material/Paper";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { i18n } from "../../translate/i18n";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import api from "../../services/api";
import toastError from "../../errors/toastError";
import ColorPicker from "../ColorPicker";
import {
	FormControl,
	IconButton,
	InputAdornment,
	Select,
	Typography
} from "@mui/material";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Colorize from '@mui/icons-material/Colorize';

import { AuthContext } from "../../context/Auth/AuthContext";
import ToastSuccess from "../../toast/success/toastSuccess";
import ToastError from "../../toast/error/toastError";

const useStyles = makeStyles()((theme) => {
	return {
		root: {
			display: "flex",
			flexWrap: "wrap",
		},
		textField: {
			marginRight: theme.spacing(1),
			flex: 1,
		},
		btnWrapper: {
			position: "relative",
		},
		buttonProgress: {
			color: green[500],
			position: "absolute",
			top: "50%",
			left: "50%",
			marginTop: -12,
			marginLeft: -12,
		},
		formControl: {
			margin: theme.spacing(1),
			minWidth: 120,
		},
		colorAdorment: {
			width: 20,
			height: 20,
		},
		multFieldLine: {
			display: "flex",
			"& > *:not(:last-child)": {
				marginRight: theme.spacing(2),
			},
			"& > *": {
				marginTop: theme.spacing(2),
			}
		},
		selectField: {
			marginTop: theme.spacing(1),
		},
	}
});

const QueueSchemaChatbot = Yup.object().shape({
	name: Yup.string()
		.min(2, "Muito Curto(a)!")
		.max(400, "Muito Longo(a)!")
		.required("Obrigatório"),
	color: Yup.string().min(3, "Muito Curto(a)!").max(10, "Muito Longo(a)!").required(),
});

const QueueSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Muito Curto(a)!")
		.max(400, "Muito Longo(a)!")
		.required("Obrigatório"),
	color: Yup.string().min(3, "Muito Curto(a)!").max(10, "Muito Longo(a)!").required(),
	greetingMessage: Yup.string().required("Obrigatório"),
	messageAccepting: Yup.string().required("Obrigatório"),
	description: Yup.string()
		.min(2, "Muito Curto(a)!")
		.max(400, "Muito Longo(a)!")
		.required("Obrigatório"),
	companyId: Yup.string(),
});

const QueueModal = ({ open, onClose, queueId, scheduleId }) => {
	const { user: loggedInUser } = useContext(AuthContext);

	const { classes } = useStyles();

	const initialState = {
		companyId: loggedInUser.companyId,
		name: "",
		color: "",
		isChatbot: false,
		chatbotId: "",
		greetingMessage: "",
		messageAccepting: "",
		description: "",
		startMonday: "08:00",
		closeMonday: "18:00",
		startTuesday: "08:00",
		closeTuesday: "18:00",
		startWednesday: "08:00",
		closeWednesday: "18:00",
		startThursday: "08:00",
		closeThursday: "18:00",
		startFriday: "08:00",
		closeFriday: "18:00",
		startSaturday: "08:00",
		closeSaturday: "12:00",
		startSunday: "00:00",
		closeSunday: "00:00",
		startInterval: "12:00",
		closeInterval: "14:00",
		usingInterval: true,
		closedMessage: ""
	};

	const [chatbot, setChatbot] = useState(false);

	const handleChatbot = (event) => {
		setChatbot(event.target.checked);
	};

	const [chatbotToQueue, setChatbotToQueue] = useState('');
	const handleChatbotId = (event) => {
		setChatbotToQueue(event.target.value);
	};

	const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
	const [queue, setQueue] = useState(initialState);
	const [tab, setTab] = useState('1')
	const [usingInterval, setUsingInterval] = useState(true)
	const greetingRef = useRef();

	const [chatbots, setChatbots] = useState([]);

	useEffect(() => {
		(async () => {
			setChatbotToQueue('')
			try {
				const { data } = await api.get(`/chatbot/`);
				setChatbots(data)
			} catch (err) {
				ToastError(err);
			}
			if (!queueId) return;
			try {
				const { data } = await api.get(`/queue/${queueId}`);
				const schedule = data?.schedule

				setUsingInterval(data?.schedule?.usingInterval);

				if (data?.chatbotId) {
					setChatbotToQueue(data.chatbotId);
				}

				setQueue(prevState => {
					return { ...prevState, ...schedule, ...data };
				});

				setChatbot(data.isChatbot);

			} catch (err) {
				ToastError(err);
			}
		})();

		return () => {
			setQueue(initialState);
			setTab('1');
		};
	}, [queueId, open]);

	const handleClose = () => {
		onClose();
		setQueue(initialState);
		setTab('1');
	};

	const handleSaveQueue = async values => {
		values.usingInterval = usingInterval;
		values.chatbotId = chatbotToQueue;
		values.isChatbot = chatbot;
		try {
			if (queueId) {
				await api.put(`/queue/${queueId}`, values);
			} else {
				await api.post("/queue", values);
			};

			ToastSuccess("Queue saved successfully");

			handleClose();
		} catch (err) {
			ToastError(err);
		}
	};

	const handleChangeTab = (e, newValue) => {
		setTab(newValue)
	}

	const handleUsingInterval = (e) => {
		setUsingInterval(e.target.value)
	}

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll="body"
				BackdropProps={{
					style: {
						backdropFilter: 'blur(3px)',
						backgroundColor: 'rgba(0, 0, 30, 0.4)',
					},
				}}
			>
				<DialogTitle>
					{queueId
						? `${i18n.t("queueModal.title.edit")}`
						: `${i18n.t("queueModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={queue}
					enableReinitialize={true}
					validationSchema={chatbot ? QueueSchemaChatbot : QueueSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveQueue(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (
						<Form>
							<TabContext value={tab}>
								<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
									<TabList onChange={handleChangeTab} aria-label="tabsEditQueues" centered>
										<Tab label={i18n.t("queueModal.form.tabs.information")} value="1" />
										<Tab label={i18n.t("queueModal.form.tabs.schedules")} value="2" />
									</TabList>
								</Box>
								<TabPanel value="1">
									<DialogContent dividers>
										<Field
											style={{ display: "none" }}
											as={TextField}
											type="hidden"
											name="companyId"
											value={loggedInUser.companyId}
										/>
										<div style={{ display: 'flex' }}>
											<Field
												as={TextField}
												label={i18n.t("queueModal.form.name")}
												autoFocus
												name="name"
												error={touched.name && Boolean(errors.name)}
												helperText={touched.name && errors.name}
												variant="outlined"
												margin="dense"
												className={classes.textField}
											/>
											<Field
												as={TextField}
												label={i18n.t("queueModal.form.color")}
												name="color"
												id="color"
												onFocus={() => {
													greetingRef.current.focus();
												}}
												onClick={() => setColorPickerModalOpen(true)}
												error={touched.color && Boolean(errors.color)}
												helperText={touched.color && errors.color}
												InputProps={{
													startAdornment: (
														<InputAdornment position="start">
															<div
																style={{ backgroundColor: values.color }}
																className={classes.colorAdorment}
															></div>
														</InputAdornment>
													),
													endAdornment: (
														<IconButton
															size="small"
															color="default"
															onClick={() => setColorPickerModalOpen(true)}
														>
															<Colorize />
														</IconButton>
													),
												}}
												variant="outlined"
												margin="dense"
											/>
										</div>
										<ColorPicker
											style={{ padding: '80px' }}
											id="modal_colorpicker"
											open={colorPickerModalOpen}
											handleClose={() => setColorPickerModalOpen(false)}
											onChange={color => {
												values.color = color;
												setQueue(() => {
													return { ...values, color };
												});
											}}
										/>
										<div>
											<FormControlLabel
												label={i18n.t("queueModal.chatbot.use")}
												control={<Checkbox
													checked={chatbot}
													onChange={handleChatbot}
													inputProps={{ 'aria-label': 'controlled' }}
												/>}
											/>
										</div>
										{chatbot ?
											<>
												<FormControl fullWidth>
													<InputLabel id="demo-simple-select-helper-label">{i18n.t("queueModal.chatbot.select")}</InputLabel>
													<Select
														labelId="demo-simple-select-helper-label"
														id="demo-simple-select-helper"
														value={chatbotToQueue}
														label={i18n.t("queueModal.chatbot.select")}
														onChange={handleChatbotId}
													>
														{chatbots.map((chatbot) => (
															<MenuItem key={chatbot.id} value={chatbot.id}>{chatbot.name}</MenuItem>
														))}
													</Select>
												</FormControl>
											</>
											:
											<>
												<div>
													<Field
														as={TextField}
														label={i18n.t("queueModal.form.messageAccepting")}
														type="messageAccepting"
														fullWidth
														multiline
														rows={2}
														disabled={!values.color}
														id='messageAcceptingField'
														name="messageAccepting"
														InputProps={{
															endAdornment: <PopupState variant="popover" popupId="demo-popup-menu">
																{(popupState) => (
																	<React.Fragment>
																		<Button size="small" disabled={!values.messageAccepting} {...bindTrigger(popupState)}>{i18n.t("queueModal.buttons.options")}</Button>
																		<Menu {...bindMenu(popupState)}>
																			<MenuItem onClick={e => {
																				popupState.close();
																				setQueue(() => {
																					return { ...values, messageAccepting: values.messageAccepting + ' ' + '[queue]' };
																				});
																			}}>{i18n.t("queueModal.markups.queue")}</MenuItem>
																			<MenuItem onClick={e => {
																				popupState.close();
																				setQueue(() => {
																					return { ...values, messageAccepting: values.messageAccepting + ' ' + '[user_name]' };
																				});
																			}}>{i18n.t("queueModal.markups.userName")}</MenuItem>
																		</Menu>
																	</React.Fragment>
																)}
															</PopupState>
														}}
														error={
															touched.messageAccepting && Boolean(errors.messageAccepting)
														}
														helperText={
															touched.messageAccepting && errors.messageAccepting
														}
														variant="outlined"
														margin="dense"
													/>
												</div>
												<div>
													<Field
														as={TextField}
														label={i18n.t("queueModal.form.description")}
														type="description"
														inputRef={greetingRef}
														fullWidth
														disabled={!values.messageAccepting}
														name="description"
														error={
															touched.greetingMessage && Boolean(errors.greetingMessage)
														}
														helperText={
															touched.greetingMessage && errors.greetingMessage
														}
														variant="outlined"
														margin="dense"
													/>
													<Field
														as={TextField}
														label={i18n.t("queueModal.form.greetingMessage")}
														type="greetingMessage"
														multiline
														inputRef={greetingRef}
														rows={4}
														disabled={!values.description}
														fullWidth
														name="greetingMessage"
														error={
															touched.greetingMessage && Boolean(errors.greetingMessage)
														}
														helperText={
															touched.greetingMessage && errors.greetingMessage
														}
														variant="outlined"
														margin="dense"
													/>
													<Field
														as={TextField}
														label={i18n.t("queueModal.form.closedMessage")}
														type="closedMessage"
														multiline
														rows={4}
														disabled={!values.description}
														fullWidth
														name="closedMessage"
														error={
															touched.closedMessage && Boolean(errors.closedMessage)
														}
														helperText={
															touched.closedMessage && errors.closedMessage
														}
														variant="outlined"
														margin="dense"
													/>
												</div>
											</>}
									</DialogContent>
								</TabPanel>
								<TabPanel value="2">
									<DialogContent dividers>

										<div className={classes.multFieldLine}>
											<div>
												<Typography variant="body1">
													{i18n.t("queueModal.form.schedules.monday")}
												</Typography>
												<Field
													as={TextField}
													name="startMonday"
													type="time"
													error={touched.name && Boolean(errors.name)}
													helperText={touched.name && errors.name}
													variant="outlined"
													margin="dense"
													className={classes.textField}
												/>
												<Field
													as={TextField}
													name="closeMonday"
													type="time"
													error={touched.name && Boolean(errors.name)}
													helperText={touched.name && errors.name}
													variant="outlined"
													margin="dense"
													className={classes.textField}
												/>

											</div>
											<div>
												<Typography variant="body1">
													{i18n.t("queueModal.form.schedules.tuesday")}
												</Typography>
												<Field
													as={TextField}
													name="startTuesday"
													type="time"
													error={touched.name && Boolean(errors.name)}
													helperText={touched.name && errors.name}
													variant="outlined"
													margin="dense"
													className={classes.textField}
												/>
												<Field
													as={TextField}
													name="closeTuesday"
													type="time"
													error={touched.name && Boolean(errors.name)}
													helperText={touched.name && errors.name}
													variant="outlined"
													margin="dense"
													className={classes.textField}
												/>
											</div>
										</div>
										<div className={classes.multFieldLine}>
											<div>
												<Typography variant="body1">
													{i18n.t("queueModal.form.schedules.wednesday")}
												</Typography>
												<Field
													as={TextField}
													name="startWednesday"
													type="time"
													error={touched.name && Boolean(errors.name)}
													helperText={touched.name && errors.name}
													variant="outlined"
													margin="dense"
													className={classes.textField}
												/>
												<Field
													as={TextField}
													name="closeWednesday"
													type="time"
													error={touched.name && Boolean(errors.name)}
													helperText={touched.name && errors.name}
													variant="outlined"
													margin="dense"
													className={classes.textField}
												/>

											</div>
											<div>
												<Typography variant="body1">
													{i18n.t("queueModal.form.schedules.thursday")}
												</Typography>
												<Field
													as={TextField}
													name="startThursday"
													type="time"
													error={touched.name && Boolean(errors.name)}
													helperText={touched.name && errors.name}
													variant="outlined"
													margin="dense"
													className={classes.textField}
												/>
												<Field
													as={TextField}
													name="closeThursday"
													type="time"
													error={touched.name && Boolean(errors.name)}
													helperText={touched.name && errors.name}
													variant="outlined"
													margin="dense"
													className={classes.textField}
												/>
											</div>
										</div>
										<div className={classes.multFieldLine}>
											<div>
												<Typography variant="body1">
													{i18n.t("queueModal.form.schedules.friday")}
												</Typography>
												<Field
													as={TextField}
													name="startFriday"
													type="time"
													error={touched.name && Boolean(errors.name)}
													helperText={touched.name && errors.name}
													variant="outlined"
													margin="dense"
													className={classes.textField}
												/>
												<Field
													as={TextField}
													name="closeFriday"
													type="time"
													error={touched.name && Boolean(errors.name)}
													helperText={touched.name && errors.name}
													variant="outlined"
													margin="dense"
													className={classes.textField}
												/>

											</div>
											<div>
												<Typography variant="body1">
													{i18n.t("queueModal.form.schedules.saturday")}
												</Typography>
												<Field
													as={TextField}
													name="startSaturday"
													type="time"
													error={touched.name && Boolean(errors.name)}
													helperText={touched.name && errors.name}
													variant="outlined"
													margin="dense"
													className={classes.textField}
												/>
												<Field
													as={TextField}
													name="closeSaturday"
													type="time"
													error={touched.name && Boolean(errors.name)}
													helperText={touched.name && errors.name}
													variant="outlined"
													margin="dense"
													className={classes.textField}
												/>
											</div>
										</div>
										<div className={classes.multFieldLine}>
											<div>
												<Typography variant="body1">
													{i18n.t("queueModal.form.schedules.sunday")}
												</Typography>
												<Field
													as={TextField}
													name="startSunday"
													type="time"
													error={touched.name && Boolean(errors.name)}
													helperText={touched.name && errors.name}
													variant="outlined"
													margin="dense"
													className={classes.textField}
												/>
												<Field
													as={TextField}
													name="closeSunday"
													type="time"
													error={touched.name && Boolean(errors.name)}
													helperText={touched.name && errors.name}
													variant="outlined"
													margin="dense"
													className={classes.textField}
												/>

											</div>
											<div>
												<Typography variant="body1">
													{i18n.t("queueModal.form.schedules.interval")}
												</Typography>
												<Field
													as={TextField}
													name="startInterval"
													type="time"
													error={touched.name && Boolean(errors.name)}
													helperText={touched.name && errors.name}
													variant="outlined"
													margin="dense"
													className={classes.textField}
												/>
												<Field
													as={TextField}
													name="closeInterval"
													type="time"
													error={touched.name && Boolean(errors.name)}
													helperText={touched.name && errors.name}
													variant="outlined"
													margin="dense"
													className={classes.textField}
												/>
											</div>
										</div>
										<div className={classes.multFieldLine}>
											<Paper>
												<Typography variant="body1">
													{i18n.t("queueModal.form.schedules.useInterval")}
												</Typography>
												<Box sx={{ minWidth: 130 }}>
													<FormControl >
														<Select
															name="usingInterval"
															value={usingInterval}
															onChange={handleUsingInterval}
															className={classes.selectField}
														>
															<MenuItem value={true}>{i18n.t("settings.settings.answerInGroups.options.enabled")}</MenuItem>
															<MenuItem value={false}>{i18n.t("settings.settings.answerInGroups.options.disabled")}</MenuItem>
														</Select>
													</FormControl>
												</Box>
											</Paper>
										</div>
									</DialogContent>
								</TabPanel>
							</TabContext>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("queueModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{queueId
										? `${i18n.t("queueModal.buttons.okEdit")}`
										: `${i18n.t("queueModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div >
	);
};

export default QueueModal;
