import isColorDark from "./isColorDark";

import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import NotificationsIcon from '@mui/icons-material/Notifications';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Box, Tooltip, Typography } from "@mui/material";

// Função para renderizar o ícone de acordo com o tipo
const renderIcon = (type, color) => {
    const icons = {
        whatsapp: <WhatsAppIcon sx={{ width: 15, height: 15, color }} />,
        schedule: <NotificationsIcon sx={{ width: 15, height: 15, color }} />,
        email: <EmailIcon sx={{ width: 15, height: 15, color }} />,
        call: <CallIcon sx={{ width: 15, height: 15, color }} />
    };
    return icons[type] || null;
};

// Definição de cores para os tipos
const renderColor = {
    whatsapp: "#25d366",
    schedule: "#ffcc00",
    email: "#cd2030",
    call: "#3923ef",
    default: "#3923ef"
};

// Função para converter cor RGB para RGBA
const rgbToRgba = (rgb, alpha = 1) => {
    const rgbValues = rgb.match(/\d+/g).map(Number);
    return `rgba(${rgbValues[0]}, ${rgbValues[1]}, ${rgbValues[2]}, ${alpha})`;
};

const calendarEventContent = (eventInfo) => {
    const { type = 'default', color: eventColor } = eventInfo.event.extendedProps || {};
    const color = eventColor || renderColor[type];

    // Define a cor do texto com base na cor de fundo
    const textColor = isColorDark("#000000") ? '#ffffff' : '#000000';
    const icon = renderIcon(type, textColor);

    return (
            <Box
                sx={{
                    border: (theme) => `0px solid ${theme.palette.primary.main}`,
                    backgroundColor: (theme) => theme.palette.primary.main,
                    borderRadius: '1px',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                    position: 'relative',
                    transition: 'background-color 0.3s ease',
                    '&:hover': {
                        backgroundColor: isColorDark("#000000") ? '#444444' : '#e0e0e0',
                    },
                }}
            >
                <Typography
                    sx={{
                        ml: 1,
                        color: textColor,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontWeight: 500,
                        fontSize: '0.9rem',
                        maxWidth: '100%',
                    }}
                >
                    {eventInfo.event.title}
                </Typography>
            </Box>
    );
};

export default calendarEventContent;
