import React, { useContext, useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import {
    Button,
    TextField,
    Dialog, Box,
    List,
    ListItem,
    DialogActions,
    Divider,
    ListItemText,
    Skeleton,
    IconButton,
    Tooltip,
    Container,
    DialogContent,
    CardHeader,
    ListItemButton,
    ListItemAvatar,
    Avatar,
    DialogTitle,
    CircularProgress,
} from "@mui/material";

import { green } from '@mui/material/colors';

import { AuthContext } from "../../../context/Auth/AuthContext";
import Paper from '@mui/material/Paper';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Fade from '@mui/material/Fade';
import SendIcon from '@mui/icons-material/Send';
import { makeStyles } from 'tss-react/mui';
import wallPaperDark from "../../../assets/wa-background-dark.png"
import wallPaper from "../../../assets/wa-background.png"
import EmojiPicker from '../../../components/EmojiPicker';
import RenderChatComponent from "../RenderChatComponent";
import isImageBase64 from "./isImageBase64";
import moment from "moment";


import api from "../../../services/api";
import toastError from "../../../errors/toastError";

import { i18n } from "../../../translate/i18n";
import ToastError from "../../../toast/error/toastError";

const useStyles = makeStyles()((theme) => {
    return {
        chatDisplayBody: {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        headerChat: {
            height: '10%',
            width: '100%'
        },
        contentChat: {
            height: '80%',
            maxHeight: '80%',
            width: '100%'
        },
        inputChat: {
            height: '10%',
            width: '100%'
        }
    }
});


function scrollToEnd() {
    var chatBody = document.querySelector("#divChat")
    if (chatBody) {
        chatBody.scrollTop = `${chatBody.scrollHeight}`
    }
}


const ChatBodyComponent = ({ chatId }) => {

    const { user } = useContext(AuthContext);
    const { classes } = useStyles();

    const [media, setMedia] = useState('');

    const [loadingHeader, setLoadingHeader] = useState(true);
    const [loading, setLoading] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [userChat, setUserChat] = useState([]);

    const setEmojiOnInput = emoji => setInputValue(`${inputValue} ${emoji}`);

    const renderBase64 = (e) => {
        const file = e.target.files[0];
        setInputValue(file.name)
        setMedia(file)
    }

    useEffect(() => {
        if (!chatId) return
        const fetchData = async () => {
            setLoadingHeader(true)
            try {
                const { data } = await api.get(`/users/${chatId}`);
                setUserChat(data)
                setLoadingHeader(false)
            } catch (error) {
                setLoadingHeader(false)
                ToastError(error)
            }
        }
        fetchData();
    }, [chatId]);


    const SendMessage = (chatId) => {
        setLoading(true)
        let fileInput = document.querySelector("#fileInput");

        const fetchData = async () => {
            try {
                let getActualDate = moment().format();
                const formData = new FormData();
                formData.append("idFrom", user.id);
                formData.append("idTo", chatId);
                formData.append("nameFrom", user.name);
                formData.append("nameTo", userChat.name);
                formData.append("message", inputValue);
                formData.append("medias", media);
                formData.append("typeMessage", media ? 'media' : 'texto');
                formData.append("sendDate", getActualDate);
                formData.append("unread", 0);
                if (!inputValue && !media.name) return;
                await api.post(`/chat-user/${chatId}`, formData);
                setTimeout(() => {
                    scrollToEnd();
                    setLoading(false)
                }, 1000);
            } catch (error) {
                setLoading(false)
                ToastError(error)
            }
        }

        fetchData();
        setMedia('');
        setInputValue('');
        fileInput.value = ''
    }

    return (
        <>
            <div className={classes.headerChat}>
                <CardHeader
                    style={{ padding: 0, margin: '5px 20px 5px 20px' }}
                    avatar={
                        loadingHeader ? (
                            <Skeleton animation="wave" variant="circular" style={{ marginTop: '5px' }} width={40} height={40} />
                        ) : (
                            <Avatar alt="User" src={userChat.media} />
                        )
                    }

                    title={
                        loadingHeader ? (
                            <Skeleton
                                animation="wave"
                                height={10}
                                width="80%"
                                style={{ marginBottom: 6 }}
                            />
                        ) : (
                            <p style={{
                                fontSize: '15px',
                                marginTop: '5px',
                                marginBottom: '-5px'
                            }}>{userChat.name}</p>
                        )
                    }
                    subheader={
                        loadingHeader ? (
                            <Skeleton animation="wave" height={10} width="40%" />
                        ) : (
                            <>{userChat.profile === 'user' ? i18n.t("userType.translate.user") : i18n.t("userType.translate.admin")}</>
                        )
                    }
                />
            </div>
            <RenderChatComponent chatId={chatId} />
            <div className={classes.inputChat} >
                <Paper
                    component="div"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
                >
                    <IconButton disabled={loading} color="primary" aria-label="upload picture" component="label">
                        <input onChange={e => { renderBase64(e) }} id="fileInput" hidden accept="video/mp4,image/jpg,image/png,image/jpeg,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,text/csv" type="file" />
                        <AttachFileIcon />
                    </IconButton>
                    <EmojiPicker value={''} setValue={setEmojiOnInput} />
                    <InputBase
                        disabled={loading}
                        value={inputValue}
                        onKeyUp={e => { if (e.key === 'Enter') { SendMessage(chatId) } }}
                        onChange={e => setInputValue(e.target.value)}
                        sx={{ ml: 1, flex: 1 }}
                        placeholder={i18n.t("internalChat.messageType")}
                        inputProps={{ 'aria-label': 'input message' }}
                    />
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <IconButton onClick={e => SendMessage(chatId)} disabled={loading} color="primary" sx={{ p: '10px' }} aria-label="directions">
                        <SendIcon />
                        {loading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: green[500],
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </IconButton>
                </Paper>
            </div>
        </>
    );


}

export default ChatBodyComponent;