import {
    Box,
    CircularProgress,
    Container,
    IconButton
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import { AuthContext } from "../../../context/Auth/AuthContext";

import { format, isSameDay, parseISO } from 'date-fns';

import api from "../../../services/api";



import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { makeStyles } from 'tss-react/mui';
import wallPaperDark from "../../../assets/wa-background-dark.png";
import wallPaper from "../../../assets/wa-background.png";


import "./chat.css";

import { RenderMedia } from '../RenderMedia';

import socket from "../../../hooks/useSocket";

import ToastError from "../../../toast/error/toastError";


const useStyles = makeStyles()((theme) => {
    return {
        chatDisplayBody: {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        headerChat: {
            height: '10%',
            width: '100%'
        },
        contentChat: {
            height: '80%',
            maxHeight: '80%',
            width: '100%'
        },
        inputChat: {
            height: '10%',
            width: '100%'
        }
    }
});


function scrollToEnd() {
    var chatBody = document.querySelector("#divChat")
    if (chatBody) {
        chatBody.scrollTop = `${chatBody.scrollHeight}`
    }
}

const RenderChatComponent = ({ chatId }) => {

    const { user } = useContext(AuthContext);

    const { classes } = useStyles();

    const [scrollButton, setScrollButton] = useState(false);

    const [pushMessage, setPushMessage] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => { setPageNumber(1); setPushMessage([]); }, [chatId]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const { data } = await api.get(`/render-chat/${chatId}`, {
                    params: { pageNumber },
                });
                setTimeout(() => {
                    if (pageNumber === 1) {
                        setPushMessage(data.messages)
                    } else {
                        setPushMessage(pushMessage => [...data.messages, ...pushMessage]);
                    }
                    setHasMore(data.hasMore)
                    if (pageNumber === 1 && data.messages.length > 1) {
                        scrollToEnd();
                    }
                    if (pushMessage.length < 1) {
                        setLoading(true)
                    }
                    setTimeout(() => {
                        setLoading(false)
                    }, 1000);
                }, 500);
            } catch (error) {
                setLoading(false)
                ToastError(error)
            }
        }
        fetchData();
    }, [pageNumber, chatId])

    useEffect(() => {



        
        socket.on('internalChatReceiver', (dataMessage) => {
            if (+dataMessage?.data?.idTo === user.id || +dataMessage?.data?.idFrom === user.id) {
                setTimeout(() => {
                    setPushMessage(pushMessage => [...pushMessage, dataMessage.data])
                    scrollToEnd();
                }, 300);
            }
        });

        return () => {
            socket.off(internalChatReceiver);
        };

    }, [])

    const RenderMessages = () => {
        if (pushMessage.length > 0) {
            const viewMessagesList = pushMessage.map((body, index) => {
                var generateIdDiv = Math.random();
                if (+body.idTo === +user.id) {
                    return <li id={generateIdDiv} className="chat_left">
                        <Box
                            component="div"
                            sx={{
                                whiteSpace: 'normal',
                                my: 2,
                                p: 1,
                                borderRadius: 2,
                                fontSize: '0.875rem',
                                fontWeight: '700',
                            }}
                        >
                            <Box
                                component="p"
                                sx={{
                                    bgcolor: (theme) =>
                                        theme.palette.mode === 'dark' ? '#202c33' : 'grey.100',
                                    borderColor: (theme) =>
                                        theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                                    color: (theme) =>
                                        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                                }}
                                id="content_chat_right">  {body.typeMessage === 'media' ? <RenderMedia nome={body.message} arquivo={body.medias} /> : body.message}
                                {body?.sendDate ? <small> {isSameDay(parseISO(body?.sendDate), new Date()) ? (
                                    <>{format(parseISO(body?.sendDate), "HH:mm")}</>
                                ) : (
                                    <>{format(parseISO(body?.sendDate), "dd/MM/yyyy")}</>
                                )}
                                </small> : ''}
                            </Box>
                        </Box>
                    </li>
                } else {
                    return <li id={generateIdDiv} className="chat_right"><Box
                        component="div"
                        sx={{
                            whiteSpace: 'normal',
                            my: 2,
                            p: 1,
                            borderRadius: 2,
                            fontSize: '0.875rem',
                            fontWeight: '700',
                        }}
                    >
                        <Box
                            component="p"
                            sx={{
                                color: (theme) =>
                                    theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                                bgcolor: (theme) =>
                                    theme.palette.mode === 'dark' ? '#005c4b' : '#9cffc8',
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                            }}
                            id="content_chat_left">  {body.typeMessage === 'media' ? <RenderMedia nome={body.message} arquivo={body.medias} /> : body.message}
                            {body?.sendDate ? <small> {isSameDay(parseISO(body?.sendDate), new Date()) ? (
                                <>{format(parseISO(body?.sendDate), "HH:mm")}</>
                            ) : (
                                <>{format(parseISO(body?.sendDate), "dd/MM/yyyy HH:mm")}</>
                            )}
                            </small> : ''}
                        </Box>
                    </Box></li>
                }
            });
            return viewMessagesList;
        } else {
            /* if (loadContent) {
                return <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'unset',
                }}>
                    <Box component="span" sx={{
                        marginTop: '10px',
                        padding: '5px',
                        borderRadius: '10px',
                        color: (theme) =>
                            theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark' ? '#4344446e' : '#ffffffc4',
                        borderColor: (theme) =>
                            theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                    }}>
                        {i18n.t("userType.translate.sayHello")}
                    </Box>
                </div>
            } */
        }
    }

    const positionScroll = () => {
        const scrollFilter = document.getElementById("divChat");
        const top = scrollFilter.scrollTop;
        const maxTop = scrollFilter.scrollHeight - scrollFilter.clientHeight;

        if ((parseInt(top) + 300) >= maxTop) {
            setScrollButton(false)
        } else {
            setScrollButton(true)
        }

        var p = document.querySelector('#perc');
        var posicoes = p.getBoundingClientRect().top;
        var position = Math.round(Number(posicoes))
        if (position >= 137 && hasMore) {
            setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
    }

    return (<>
        <Container
            id="divChat"
            className={classes.contentChat}
            onScroll={positionScroll}
            sx={{
                overflow: 'auto',
                backgroundSize: '450px',
                backgroundImage: (theme) => theme.palette.mode === 'dark' ? "url(" + wallPaperDark + ")" : "url(" + wallPaper + ")"
            }} >
            <ul id="perc">
                {loading === true &&
                    <div style={{
                        marginTop: 30,
                        display: 'flex',
                        justifyContent: 'center',
                        position: 'unset'
                    }}>
                        <CircularProgress />
                    </div>
                }
                {RenderMessages()}
            </ul>
            {scrollButton && <div style={{ transition: 1, position: 'absolute', zIndex: 1, top: '455px', right: 25 }}>
                <IconButton sx={{ backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#212b36e6' : '#ffffffa1' }} aria-label="delete">
                    <KeyboardArrowDownIcon onClick={e => scrollToEnd()} />
                </IconButton>
            </div>}
        </Container>

    </>);
}

export default RenderChatComponent;