import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
	Box,
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	MenuItem,
	Select,
	TextField,
	Tooltip,
	Typography
} from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import { styled } from "@mui/system";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ptBR } from "date-fns/locale";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from '../../context/Auth/AuthContext';
import api from "../../services/api";
import ToastError from "../../toast/error/toastError";
import generateRandomColor from "../../utils/generateRandomColor";
import AutoCompleteFetchedStyled from '../AutoCompleteFetchedStyled';
import AutoCompleteStyled from "../AutoCompleteStyled";
import DropZoneUpload from '../DropZoneUpload';
import SwitchStyled from "../SwitchStyled";

const CustomDialogTitle = styled(DialogTitle)({
	color: "#fff",
	textAlign: "center",
});

const CustomDialogActions = styled(DialogActions)({
	justifyContent: "center",
	padding: "16px 24px",
});

const days = [
	{ label: "Domingo", key: "sunday" },
	{ label: "Segunda", key: "monday" },
	{ label: "Terça", key: "tuesday" },
	{ label: "Quarta", key: "wednesday" },
	{ label: "Quinta", key: "thursday" },
	{ label: "Sexta", key: "friday" },
	{ label: "Sábado", key: "saturday" },
];

const initialErrors = {
	title: false,
	startAt: false,
	endAt: false,
	timers: false,
	weekDays: false,
	description: false,
	connection: false,
	messageOrFile: false
};

const initialLoading = {
	tags: false,
	users: false,
	contacts: false,
	submit: false,
	connections: false
};

const initialWeekDays = {
	sunday: false,
	monday: false,
	tuesday: false,
	wednesday: false,
	thursday: false,
	friday: false,
	saturday: false
};

// Função para comparar se o startTime é antes do endTime
const isStartBeforeEnd = (startTime, endTime) => {
	const startHours = startTime.getHours();
	const startMinutes = startTime.getMinutes();
	const endHours = endTime.getHours();
	const endMinutes = endTime.getMinutes();

	if (startHours > endHours) {
		return false;
	} else if (startHours === endHours && startMinutes > endMinutes) {
		return false;
	}

	return true;
};


const CalendarModal = ({ calendarId = null, open, onClose, onConfirm }) => {

	const { user } = useContext(AuthContext);

	// Loadings
	const [loading, setLoading] = useState(initialLoading);

	// Datas
	const [startAt, setStartAt] = useState(new Date());
	const [endAt, setEndAt] = useState(startAt || new Date());

	// Horarios
	const [startTime, setStartTime] = useState(new Date());
	const [endTime, setEndTime] = useState(new Date());

	// Cor - Desabilitado
	const [color, setColor] = useState(generateRandomColor());

	const [title, setTitle] = useState("");

	const [description, setDescription] = useState("");

	const [recurrence, setRecurrence] = useState('ONCE');
	const [numberOfRecurrence, setNumberOfRecurrence] = useState(1);

	const [finishOn, setFinishOn] = useState('date');

	const [isAlert, setIsAlert] = useState(false);
	const [tags, setTags] = useState([]);
	const [tagsArray, setTagsArray] = useState([]);

	const [users, setUsers] = useState([user]);
	const [usersArray, setUsersArray] = useState([user]);
	const [contacts, setContacts] = useState([]);
	const [contactsArray, setContactsArray] = useState([]);
	const [searchContact, setSearchContact] = useState(null);

	const [weekDays, setDays] = useState(initialWeekDays);

	const [status, setStatus] = useState('await');

	const [files, setFiles] = useState([]);

	const [sendMessage, setSendMessage] = useState(true);
	const [sendFile, setSendFile] = useState(true);

	const [createOrSendToTicket, setCreateOrSendToTicket] = useState(true);

	const [message, setMessage] = useState("");

	const [connection, setConnection] = useState([]);
	const [connectionsArray, setConnectionsArray] = useState([]);

	const [FORM_ERRORS, setFormErrors] = useState(initialErrors);

	const handleChangeColor = (color) => {
		setColor(color)
	}

	const handleChangeTitle = (e) => {
		setTitle(e.target.value);
	};

	const handleClose = () => {
		setStartAt(new Date());
		setEndAt(new Date());
		setStartTime(new Date());
		setEndTime(new Date());
		setTitle("");
		setDescription("");
		setRecurrence('ONCE');
		setNumberOfRecurrence(1);
		setFinishOn('date');
		setContacts([]);
		setConnection([]);
		setDays(initialWeekDays);
		setStatus('await');
		setLoading(initialLoading);
		setFiles([]);
		onClose(false);
	};

	const handleDrop = useCallback(
		(acceptedFiles) => {
			const newFiles = acceptedFiles.map((file) =>
				Object.assign(file, {
					preview: URL.createObjectURL(file),
				})
			);
			setFiles([...files, ...newFiles]);
		},
		[files]
	);

	const handleRemoveFile = (inputFile) => {
		const filtered = files.filter((file) => file !== inputFile);
		setFiles(filtered);
	};

	useEffect(() => {
		const currentDay = new Date().getDay();
		const dayMap = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
		setDays((prevDays) => ({
			...prevDays,
			[dayMap[currentDay]]: true,
		}));
	}, []);

	const handleChangeFinishOn = (event) => {
		setFinishOn(event.target.value);
	};

	const handleChangeRecurrence = (event) => {
		setRecurrence(event.target.value);
	};

	const handleDayChange = (day) => {
		setDays((prevState) => ({
			...prevState,
			[day]: !prevState[day],
		}));
	};

	useEffect(() => {
		if (open) {
			const now = new Date();
			setStartAt(now);
			setStartTime(now);
			setEndTime(now);
		}
	}, [open]);

	const validateFields = () => {
		const errors = {
			title: !title,
			startAt: !startAt,
			endAt: finishOn == 'date' && !endAt,
			timers: !isStartBeforeEnd(startTime, endTime),
			weekDays: recurrence == 'WEEKLY' && !Object.values(weekDays).some(day => day === true),
			description: !description,
			connection: sendMessage && !connection || connection.length == 0,
			messageOrFile: sendMessage && (!message && files.length == 0),
		};

		setFormErrors(errors);

		const hasErrors = Object.values(errors).some((error) => error);

		if (hasErrors) {
			ToastError('Preencha todos os campos corretamente');
		}

		return hasErrors;
	};

	const handleConfirm = async () => {

		if (validateFields()) return;

		setLoading((prevLoading) => ({ ...prevLoading, submit: true }));

		const formData = new FormData();
		formData.append("title", title);
		formData.append("finishOn", finishOn);
		formData.append("description", description);
		formData.append("startAt", startAt.toISOString());
		formData.append("startTime", startTime.toTimeString().split(' ')[0]);
		formData.append("endTime", endTime.toTimeString().split(' ')[0]);
		formData.append("days", JSON.stringify(weekDays));
		formData.append("recurrence", recurrence);
		formData.append("color", color);
		formData.append("isAlert", isAlert);
		formData.append("sendMessage", sendMessage);
		formData.append("sendFile", sendFile);
		formData.append("createOrSendToTicket", createOrSendToTicket);
		formData.append("message", message);

		if (files.length > 0) { formData.append("medias", files[0]); };

		if (finishOn === 'date') { formData.append("endAt", endAt.toISOString()); };

		if (tags.length > 0) { formData.append("tagsId", JSON.stringify(tags.map(t => t.id))) };

		if (users.length > 0) { formData.append("usersId", JSON.stringify(users.map(u => u.id))) };

		if (contacts.length > 0) { formData.append("contactsId", JSON.stringify(contacts.map(c => c.id))) };

		if (connection.length > 0) { formData.append("connectionsId", JSON.stringify(connection.map(c => c.id))) };


		try {
			const endpoint = calendarId ? `/calendar/${calendarId}` : `/calendar`;
			const method = calendarId ? api.put : api.post;
			const data = await method(endpoint, formData);
			onConfirm(data);
			handleClose();
		} catch (err) {
			console.error("Error scheduling description:", err);
			ToastError("Erro ao agendar mensagem.");
		} finally {
			setLoading((prevLoading) => ({ ...prevLoading, submit: false }));
		}
	};

	const addUniqueItems = (existingArray, newItems) => {
		const uniqueItems = [
			...existingArray,
			...newItems.filter(newItem =>
				!existingArray.some(existingItem => existingItem.id === newItem.id)
			)
		];

		return uniqueItems.sort((a, b) => {
			if (a.name < b.name) return -1;
			if (a.name > b.name) return 1;
			return 0;
		});
	};

	const fetchInitialData = async () => {
		setLoading(prevLoading => ({ ...prevLoading, tags: true, users: true, connections: true }));

		try {
			const [tagsResponse, usersResponse, whatsappsResponse] = await Promise.all([
				api.get('/tags', { params: { type: "schedules" } }),
				api.get('/users'),
				api.get('/whatsapp')
			]);

			const newTags = tagsResponse.data;
			const newUsers = usersResponse.data.users;
			const newConnections = whatsappsResponse.data;

			setTagsArray(prevTags => addUniqueItems(prevTags, newTags));
			setUsersArray(prevUsers => addUniqueItems(prevUsers, newUsers));
			setConnectionsArray(prevConnections => addUniqueItems(prevConnections, newConnections));
		} catch (error) {
			console.error("Error fetching initial data:", error);
		} finally {
			setLoading(prevLoading => ({ ...prevLoading, tags: false, users: false, connections: false }));
		}

		await new Promise(resolve => setTimeout(resolve, 1000)); // Ajuste se necessário
	};

	const fetchCalendarData = async () => {
		try {

			setLoading(prevLoading => ({ ...prevLoading, contacts: false, submit: false }));

			const { data } = await api.get(`/calendar/${calendarId}`);

			setStartAt(new Date(data.startAt));
			setEndAt(new Date(data.endAt));
			setStartTime(parseTime(data.startTime));
			setEndTime(parseTime(data.endTime));
			setColor(data.color);
			setTitle(data.title);
			setRecurrence(data.recurrence);
			setDescription(data?.description);
			setFinishOn(data?.endAt ? 'date' : 'recurrence');
			setContacts(data.contacts);
			setTags(data.tags);
			setUsers(data.users);
			setIsAlert(data.isAlert);
			setDays(JSON.parse(data.days));
			setMessage(data.message);

			if (data?.whatsapp) setConnection([data.whatsapp]);
			if (data?.fileBlob && data?.messageMedia) {
				setFiles([])
				const fileName = data.messageMedia.split("/").pop();
				const fileBlob = data.fileBlob;
				const newFile = new File([fileBlob], fileName, { type: fileBlob.type });
				const newFileWithPreview = Object.assign(newFile, { preview: URL.createObjectURL(newFile) });
				setFiles([...files, newFileWithPreview]);
			}


		} catch (error) {
			console.error("Error fetching calendar data:", error);
		} finally {
			setLoading(prevLoading => ({ ...prevLoading, contacts: false, submit: false }));
		}
	};

	const parseTime = (timeString) => {
		const [hours, minutes] = timeString.split(':');
		const today = new Date();
		return new Date(today.getFullYear(), today.getMonth(), today.getDate(), Number(hours), Number(minutes));
	};

	useEffect(() => {
		if (open) {
			fetchInitialData();
			if (calendarId) {
				fetchCalendarData();
			}
		}
	}, [open, calendarId]);

	useEffect(() => {
		(async () => {
			if (open) {
				try {
					setLoading((prevLoading) => ({ ...prevLoading, contacts: true }));
					const { data } = await api.get('/contacts', { params: { searchParam: searchContact } });
					setContactsArray(data.contacts || []);
				} catch (error) {
					console.error(error);
				} finally {
					setTimeout(() => {
						setLoading((prevLoading) => ({ ...prevLoading, contacts: false }));
					}, 1000);
				};
			}
		})();
	}, [open, calendarId, searchContact]);


	const handleTextLimit = (text, type, maxLength) => {
		const stateSetters = {
			description: setDescription,
			message: setMessage,
		};
		const setState = stateSetters[type];
		if (setState) {
			setState(text.slice(0, maxLength));
		}
	};

	return (
		<Dialog
			open={open}
			onClose={() => handleClose()}
			aria-labelledby="calendars-dialog"
			fullWidth
			sx={{
				"& .MuiDialog-container": {
					"& .MuiPaper-root": {
						width: "100%",
						maxWidth: "1300px",
					},
				},
			}}
		>
			<DialogTitle id="calendars-dialog">{`Novo agendamento`}</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					{/* Coluna da esquerda */}
					<Grid item xs={12} sm={6}>
						<Grid container spacing={2}>
							{/* Título */}
							<Grid item xs={8}>
								<TextField
									sx={{ marginTop: '18px' }}
									label="Título*"
									value={title}
									onChange={handleChangeTitle}
									fullWidth
									margin="dense"
									size="small"
									inputProps={{ maxLength: 100 }}
									error={FORM_ERRORS.title || title.length == 0}
									helperText={FORM_ERRORS.title || title.length == 0 ? "O título é obrigatório" : ""}
								/>
							</Grid>
							<Grid item xs={4}>
								<Box sx={{
									marginTop: '18px',
									display: 'flex',
									alignItems: 'center',
									marginBottom: '12px'
								}}>
									<FormGroup sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
										<FormControlLabel
											control={<SwitchStyled />}
											label={
												<Box
													sx={{
														display: 'flex',
														alignItems: 'center',
														justifyContent: ' flex-end',
														flexDirection: 'row',
													}}
												>
													<Typography variant="body1" sx={{ fontSize: '13px' }}>
														Alertar
													</Typography>
													{/* Ajustando o Tooltip */}
													<Tooltip title="Ao ativar esta opção, um alerta será exibido na tela dos contatos vinculados na data inicial deste evento.">
														<HelpOutlineIcon sx={{ width: '20px', height: '20px', marginLeft: '5px' }} />
													</Tooltip>
												</Box>
											}
											checked={isAlert}
											onChange={e => setIsAlert(e.target.checked)}
										/>
									</FormGroup>
								</Box>

							</Grid>

							{/* Data Agendamento e Horários */}
							<Grid item xs={6}>
								<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
									<Box sx={{ display: 'flex', gap: 2, flexDirection: { xs: 'column', sm: 'row' } }}>
										<DatePicker
											label="Data Agendamento*"
											value={startAt}
											onChange={(newValue) => setStartAt(newValue)}
											format="dd/MM/yyyy"
											minDate={new Date()}
											slotProps={{ textField: { size: 'small', fullWidth: true, margin: 'dense' } }}
										/>
									</Box>
								</LocalizationProvider>
							</Grid>

							{/* Horarios do evento */}
							<Grid item xs={3}>
								<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
									<TimePicker
										label="Horário inicial*"
										value={startTime}
										onChange={(newValue) => setStartTime(newValue)}
										ampm={false}
										slotProps={{ textField: { size: 'small', fullWidth: true, margin: 'dense', error: !isStartBeforeEnd(startTime, endTime), helperText: !isStartBeforeEnd(startTime, endTime) ? 'O horário inicial deve ser antes do horário final.' : '' } }}
									/>
								</LocalizationProvider>
							</Grid>
							<Grid item xs={3}>
								<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
									<TimePicker
										label="Horário final*"
										value={endTime}
										onChange={(newValue) => setEndTime(newValue)}
										ampm={false}
										slotProps={{ textField: { size: 'small', fullWidth: true, margin: 'dense', error: !isStartBeforeEnd(startTime, endTime), helperText: !isStartBeforeEnd(startTime, endTime) ? 'O horário final deve ser depois do horário inicial.' : '' } }}
									/>
								</LocalizationProvider>
							</Grid>

							{/* Repetição e Finalização */}
							<Grid item xs={recurrence === 'ONCE' ? 12 : 6}>
								<Typography variant="body2">Repetir:</Typography>
								<FormControl fullWidth size="small">
									<Select
										labelId="recurrence-select"
										id="recurrence-select"
										value={recurrence}
										onChange={handleChangeRecurrence}
										sx={{ borderRadius: '10px' }}
									>
										<MenuItem value="ONCE">Não repetir</MenuItem>
										<MenuItem value="WEEKLY">Semanal</MenuItem>
										<MenuItem value="MONTHLY">Mensal</MenuItem>
										<MenuItem value="ANNUALLY">Anual</MenuItem>
									</Select>
								</FormControl>
							</Grid>

							{recurrence !== 'ONCE' && (
								<Grid item xs={6}>
									<Typography variant="body2">Finalizar:</Typography>
									<Box sx={{ display: 'flex' }}>
										<FormControl size="small" sx={{ width: finishOn === 'recurrence' ? '60%' : finishOn === 'date' ? '70%' : '100%' }}>
											<Select
												disabled={recurrence === 'ONCE' || status !== 'await'}
												value={finishOn}
												onChange={handleChangeFinishOn}
												sx={{ borderRadius: finishOn !== 'never' ? '10px 0 0 10px' : '10px' }}
											>
												<MenuItem value="recurrence">Recorrências</MenuItem>
												<MenuItem value="date">Na data</MenuItem>
											</Select>
										</FormControl>
										{finishOn === 'date' && (
											<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
												<DatePicker
													value={endAt}
													onChange={setEndAt}
													format="dd/MM/yyyy"
													minDate={startAt || new Date()}
													sx={{
														'& .MuiOutlinedInput-root': { borderRadius: '0px 10px 10px 0px' }
													}}
													slotProps={{ textField: { size: 'small', fullWidth: true } }}
												/>
											</LocalizationProvider>
										)}
										{finishOn === 'recurrence' && (
											<TextField
												type="number"
												size="small"
												sx={{ width: '40%', margin: '0px', borderRadius: '0px 10px 10px 0px' }}
												value={numberOfRecurrence}
												onChange={e => setNumberOfRecurrence(e.target.value)}
												inputProps={{ min: 0, max: 30 }}
											/>
										)}
									</Box>
								</Grid>
							)}

							{/* Repetição por Dias */}
							{recurrence === 'WEEKLY' && (
								<Grid item xs={12}>
									<Typography variant="body2">Repetir nos dias:*</Typography>

									<Box sx={{
										marginTop: '2px',
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										flexWrap: 'wrap',
										gap: 1, // Adiciona um espaçamento consistente entre os itens
									}}>
										{days.map(day => {
											const isDaySelected = weekDays[day.key];
											const hasError = !Object.values(weekDays).some(value => value === true);

											return (
												<Tooltip key={day.key} title={day.label}>
													<Checkbox
														disabled={status !== 'await'}
														icon={<Chip
															label={day.label}
															size="small"
															variant="outlined"
															color={hasError ? 'error' : 'default'}
														/>}
														checkedIcon={<Chip
															label={day.label}
															size="small"
															color="success"
														/>}
														checked={isDaySelected}
														onChange={() => handleDayChange(day.key)}
														sx={{ padding: '2px' }}
													/>
												</Tooltip>
											);
										})}
									</Box>

									{!Object.values(weekDays).some(value => value === true) && (
										<Typography variant="body2" sx={{ color: '#ff645f', marginTop: 1 }}>
											Deve ser selecionado ao menos um dia da semana para o evento.
										</Typography>
									)}
								</Grid>
							)}

							{/* Descrição */}
							<Grid item xs={12}>
								<TextField
									label="Descrição*"
									value={description}
									onChange={e => handleTextLimit(e.target.value, 'description', 2000)}
									fullWidth
									margin="dense"
									multiline
									rows={7}
									size="small"
									error={(FORM_ERRORS.description && description.length == 0) || description.length >= 2000}
									helperText={(FORM_ERRORS.description && description.length == 0) ? 'Descrição do evento é necessária.' : description.length < 2000 ? `${description.length}/2000` : `${description.length}/2000 - Máximo de 2000 caracteres atingido`}
									inputProps={{ maxLength: 2000 }}
								/>
							</Grid>
							<Grid item xs={12}>
								<AutoCompleteStyled
									multiple
									loading={loading.users}
									label="Vincular usuários (Opcional)"
									options={usersArray}
									values={users}
									onSelect={setUsers}
								/>
							</Grid>
						</Grid>
					</Grid>

					{/* Coluna da direita */}
					<Grid item xs={12} sm={6}>
						<Grid container spacing={2}>

							{/* Vincular Contato */}
							<Grid item xs={12}>
								<Box sx={{
									marginTop: '18px',
									display: 'flex',
									alignItems: 'center',
									marginBottom: '12px'
								}}>
									<AutoCompleteFetchedStyled
										multiple
										loading={loading.contacts}
										label="Vincular contatos (Opcional)"
										options={contactsArray}
										values={contacts}
										onSelect={e => setContacts(e)}
										fetch={setSearchContact}
									/>
								</Box>
							</Grid>
							{/*
							<Grid item xs={12}>
								<AutoCompleteStyled
									multiple
									loading={loading.tags}
									label="Atribuir Tags"
									options={tagsArray}
									values={tags}
									onSelect={setTags}
								/>
							</Grid>
							*/}

							{/* Configurações de Envio */}
							{contacts.length > 0 && (
								<>
									<Grid item xs={12}>
										<Box sx={{ paddingTop: '6px !important', paddingBottom: '27px !important' }}>
											<FormGroup sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
												<FormControlLabel
													control={<SwitchStyled />}
													label={
														<Box
															sx={{
																display: 'flex',
																alignItems: 'center',
																justifyContent: 'space-around',
																flexDirection: 'row'
															}}
														>
															<Typography variant="body1" sx={{ fontSize: '13px' }}>
																Enviar Mensagem para contatos
															</Typography>
															{/* Ajustando o Tooltip */}
															<Tooltip title="Quando esta opção for ativada, uma mensagem será enviada automaticamente aos contatos selecionados para o agendamento, no horário inicial definido para o evento.">
																<HelpOutlineIcon sx={{ width: '20px', height: '20px', marginLeft: '5px' }} />
															</Tooltip>
														</Box>
													}
													checked={sendMessage}
													onChange={e => setSendMessage(e.target.checked)}
												/>
												{sendMessage &&
													(
														<FormControlLabel
															control={<SwitchStyled />}
															label={
																<Box
																	sx={{
																		display: 'flex',
																		alignItems: 'center',
																		justifyContent: 'space-around',
																		flexDirection: 'row'
																	}}
																>
																	<Typography variant="body1" sx={{ fontSize: '13px' }}>
																		Salvar mensagem no chat
																	</Typography>
																	{/* Ajustando o Tooltip */}
																	<Tooltip title="Quando esta opção estiver ativada, o sistema buscará por um atendimento ativo vinculado aos contatos selecionados. Se nenhum atendimento ativo for encontrado, um novo atendimento será criado para cada contato e marcado como pendente, utilizando a mensagem enviada.">
																		<HelpOutlineIcon sx={{ width: '20px', height: '20px', marginLeft: '5px' }} />
																	</Tooltip>
																</Box>
															}
															checked={createOrSendToTicket}
															onChange={e => setCreateOrSendToTicket(e.target.checked)}
														/>
													)
												}
											</FormGroup>
										</Box>
									</Grid>

									{sendMessage && (
										<>
											{/* Conexão de Envio */}
											<Grid item xs={12}>
												<AutoCompleteStyled
													error={connection.length == 0}
													multiple
													loading={loading.connections}
													label="Vincular conexão de envio"
													options={connectionsArray}
													values={connection}
													onSelect={setConnection}
												/>

												{connection.length == 0 && (
													<Typography variant="body2" sx={{ color: '#ff645f', marginTop: 1 }}>
														É necessário selecionar uma conexão para o envio da mensagem
													</Typography>
												)}

											</Grid>

											{/* Mensagem */}
											<Grid item xs={12}>
												<TextField
													label="Mensagem"
													value={message}
													onChange={e => handleTextLimit(e.target.value, 'message', 1000)}
													fullWidth
													multiline
													rows={recurrence === 'WEEKLY' ? 10 : 7}
													margin="dense"
													size="small"
													inputProps={{ maxLength: 1000 }}
													error={(FORM_ERRORS.messageOrFile && message.length == 0) || message.length >= 1000}
													helperText={(FORM_ERRORS.messageOrFile && message.length == 0) ? 'Escreva uma mensagem ou selecione um arquivo.' : message.length < 1000 ? `${message.length}/1000` : `${message.length}/1000 - Máximo de 1000 caracteres atingido`}
												/>
											</Grid>
											<Grid item xs={12}>
												<DropZoneUpload
													multiple
													files={files}
													onDrop={handleDrop}
													onRemove={handleRemoveFile}
												/>
											</Grid>
										</>
									)}
								</>
							)}
						</Grid>
					</Grid>
				</Grid>

			</DialogContent>
			<CustomDialogActions>
				<Button onClick={() => handleClose()} color="error" variant="outlined" size="small" disabled={loading.submit}>
					Cancelar
				</Button>
				<Button onClick={handleConfirm} color="primary" variant="contained" size="small" disabled={loading.submit}>
					{loading.submit ? "Carregando..." : "Confirmar"}
				</Button>
			</CustomDialogActions>
		</Dialog >
	);
};

export default CalendarModal;
