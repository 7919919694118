// React
import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

// MUI
import { Badge } from "@mui/material";
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from "@mui/material/ListItem";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import { useTheme } from '@mui/material/styles';

// Contextos
import { Can } from "../components/Can";
import { AuthContext } from "../context/Auth/AuthContext";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { i18n } from "../translate/i18n";

// Ícones do MUI
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AlarmRoundedIcon from '@mui/icons-material/AlarmRounded';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CellWifiIcon from '@mui/icons-material/CellWifi';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import ForumIcon from '@mui/icons-material/Forum';
import HeadsetMicRoundedIcon from '@mui/icons-material/HeadsetMicRounded';
import KeyIcon from '@mui/icons-material/Key';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';


// Outros
import socket from '../hooks/useSocket';
import "./styleMenu.css";


function ListItemLink(props) {
  const { icon, primary, to, className, id } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <ListItem id={id} style={{ listStyle: "none", padding: '5px 17px' }}>
      <ListItem color="primary"
        sx={{
          borderRadius: '5px',
          color: (theme) => window.location.pathname === id ?
            theme.palette.primary.main
            : theme.palette.mode === "dark" ? '#FFFFFF' : '#000',
        }}
        button component={renderLink}
        className={className}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </ListItem>
  );
}

const CustomListItem = ({ pathnames, children, theme, ...props }) => {
  const { primary } = theme.palette;
  const isHighlightedPath = pathnames.includes(window.location.pathname);
  return (
    <ListItem
      sx={{
        color: isHighlightedPath ? primary.main : 'inherit',
        listStyle: "none",
        padding: '5px 17px'
      }}
      {...props}
    >
      {children}
    </ListItem>
  );
}

const MainListItems = (isCollapse) => {
  const theme = useTheme();
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user } = useContext(AuthContext);
  const [connectionWarning, setConnectionWarning] = useState(false);
  const [menuKeywords, setMenuKeywords] = useState(false);
  const [open, setOpen] = useState(true);


  const handleClick = (menuToOpen) => {
    setOpen(menuToOpen === open ? null : menuToOpen);
  };

  useEffect(() => {
    if (!user) return;
    const { company, companyId } = user;

    if (company?.useKeywords !== undefined) {
      setMenuKeywords(company.useKeywords);
    }

    socket.on(`changeKeywords-${companyId}`, ({ useKeywords }) => {
      setMenuKeywords(useKeywords);
    });

    return () => {
      socket.off(`changeKeywords-${companyId}`);
    };
  }, [user]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0 && user.id) {
        const offlineWhats = whatsApps.filter((whats) => {
          return ((
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          ) && whats.companyId === user.companyId);
        });
        setConnectionWarning(offlineWhats.length > 0);
      }
    }, 5000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  return (
    <List>
      <ListSubheader style={{ borderRadius: '8px', margin: '8px' }}>
        {"Menu"}
      </ListSubheader>
      <Can
        role={user.profile}
        perform="drawer-admin-items:company"
        yes={() => (
          <ListItemLink
            to="/companies/system-overview"
            id="/companies/system-overview"
            primary={!isCollapse.collapse && i18n.t("companies.list.overview")}
            icon={<SpaceDashboardIcon />}
          />
        )}
      />
      {user.profile !== 'adminSuper' && <>
        {/* Grupo de Navegação Geral */}
        <ListItemLink
          to="/"
          id="/"
          primary={!isCollapse.collapse && 'Dashboard'}
          icon={<DashboardCustomizeIcon />}
        />
        <ListItemLink
          to="/tickets"
          id="/tickets"
          primary={!isCollapse.collapse && i18n.t("mainDrawer.listItems.tickets")}
          icon={<ForumIcon />}
        />
        <ListItemLink
          to="/contacts"
          id="/contacts"
          primary={!isCollapse.collapse && i18n.t("mainDrawer.listItems.contacts")}
          icon={<ContactPhoneIcon />}
        />

        {/* Grupo de Administração */}
        <Can
          role={user.profile}
          perform="drawer-admin-items:view"
          yes={() => (
            <>
              <ListSubheader style={{ borderRadius: '8px', margin: '8px' }}>
                {isCollapse.collapse ? i18n.t("mainDrawer.listItems.admin") : i18n.t("mainDrawer.listItems.administration")}
              </ListSubheader>

              <List component="div" disablePadding>
                <ListItemLink
                  to="/users"
                  id="/users"
                  primary={!isCollapse.collapse && i18n.t("mainDrawer.listItems.users")}
                  icon={<PeopleAltIcon />}
                />

                <ListItemLink
                  to="/connections"
                  id="/connections"
                  primary={!isCollapse.collapse && i18n.t("mainDrawer.listItems.connections")}
                  icon={
                    <Badge badgeContent={connectionWarning ? "!" : 0} color="error">
                      <CellWifiIcon />
                    </Badge>
                  }
                />
              </List>
            </>
          )}
        />

        {/* Grupo de Organização */}
        <ListItemButton onClick={() => handleClick('organization')}>
          <CustomListItem theme={theme} pathnames={['/chatbot', '/queues', '/tags']}>
            <ListItemIcon>
              <HeadsetMicRoundedIcon />
            </ListItemIcon>
            <ListItemText primary={!isCollapse.collapse && 'Atendimento'} />
          </CustomListItem>
          {!isCollapse.collapse && <>{open === 'organization' ? <ArrowRightIcon /> : <ArrowDropDownIcon />}</>}
        </ListItemButton>
        <Collapse in={open === 'organization' && !isCollapse.collapse} timeout="auto" unmountOnExit>
          <List sx={{ paddingLeft: '20px' }} component="div" disablePadding>
            {/*
            <ListItemLink
              to="/folder"
              id="/folder"
              primary={!isCollapse.collapse && i18n.t("mainDrawer.listItems.folder")}
              icon={<StorageIcon />}
            />
            */}
            <ListItemLink
              to="/queues"
              id="/queues"
              primary={!isCollapse.collapse && i18n.t("mainDrawer.listItems.queues")}
              icon={<AccountTreeIcon />}
            />
            <ListItemLink
              to="/tags"
              id="/tags"
              primary={!isCollapse.collapse && i18n.t("mainDrawer.listItems.tags")}
              icon={<LocalOfferIcon />}
            />
            <ListItemLink
              to="/schedules"
              id="/schedules"
              primary={!isCollapse.collapse && i18n.t("mainDrawer.listItems.schedules")}
              icon={<AlarmRoundedIcon />}
            />
            <ListItemLink
              to="/chatbot"
              id="/chatbot"
              primary={!isCollapse.collapse && i18n.t("mainDrawer.listItems.chatbot")}
              icon={<SmartToyIcon />}
            />
          </List>
        </Collapse>
        {/* Agenda de eventos */}
        <List component="div" disablePadding>
          <ListItemLink
            to="/calendar"
            id="/calendar"
            primary={!isCollapse.collapse && i18n.t("mainDrawer.listItems.calendar")}
            icon={<CalendarTodayIcon />}
          />
        </List>
        {/* Grupo de Relatórios 
        <List component="div" disablePadding>
          <ListItemLink
            to="/report-tickets"
            id="/report-tickets"
            primary={!isCollapse.collapse && i18n.t("mainDrawer.listItems.reportTickets")}
            icon={<QueryStatsIcon />}
          />
        </List>*/}
      </>}

      {/* Grupo de Configurações */}
      <ListSubheader style={{ borderRadius: '8px', margin: '8px' }}>
        {i18n.t("mainDrawer.listItems.settings")}
      </ListSubheader>
      <ListItemLink
        to="/settings"
        id="/settings"
        primary={!isCollapse.collapse && i18n.t("mainDrawer.listItems.settings")}
        icon={<SettingsOutlinedIcon />}
      />
      {menuKeywords &&
        <ListItemLink
          to="/keywords"
          id="/keywords"
          primary={!isCollapse.collapse && i18n.t("mainDrawer.listItems.keywords")}
          icon={<KeyIcon />}
        />}
    </List>
  );
};

export default MainListItems;
