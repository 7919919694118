import React, { useContext, useEffect, useRef, useState } from "react";

import { format } from "date-fns";
import { useHistory } from "react-router-dom";

import NotificationsIcon from '@mui/icons-material/Notifications';
import { ListItemText, Typography } from '@mui/material';
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import { makeStyles } from 'tss-react/mui';
import { AuthContext } from "../../context/Auth/AuthContext";
import useResponsive from '../../hooks/useResponsive';
import useTickets from "../../hooks/useTickets";
import { i18n } from "../../translate/i18n";
import Scrollbar from '../Scrollbar';
import TicketListItem from "../TicketListItem";

import socket from "../../hooks/useSocket";
import MenuPopover from '../MenuPopover';

const useStyles = makeStyles()((theme) => {
	return {
		tabContainer: {
			overflowY: "auto",
			maxHeight: 400,
			...theme.scrollbarStyles,
		},
		popoverPaper: {
			width: "100%",
			maxWidth: 400,
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(1),
			[theme.breakpoints.down("sm")]: {
				maxWidth: 270,
			},
		},
		noShadow: {
			boxShadow: "none !important",
		},
	}
});


const NotificationsPopOver = () => {
	const { classes } = useStyles();

	const history = useHistory();
	const { user } = useContext(AuthContext);
	const ticketIdUrl = +history.location.pathname.split("/")[2];
	const ticketIdRef = useRef(ticketIdUrl);
	const anchorEl = useRef();
	const [isOpen, setIsOpen] = useState(false);
	const [notifications, setNotifications] = useState([]);
	const { companyId, profile, queues } = user;
	const [, setDesktopNotifications] = useState([]);
	const [volume, setVolume] = useState(50);
	const { tickets } = useTickets({ withUnreadMessages: "true" });
	const historyRef = useRef(history);
	const smUp = useResponsive('up', 'sm');

	useEffect(() => {
		if (smUp) { Notification.requestPermission() }
		setVolume((localStorage.getItem("volume") ? Number(localStorage.getItem("volume")) : 50))
	}, []);

	useEffect(() => {
		const queueIds = queues.map((q) => q.id);
		const filteredTickets = tickets.filter((t) => queueIds.indexOf(t.queueId) > -1);
		const filteredTicketsSupervisor = tickets.filter((t) => queueIds.indexOf(t.queueId) > -1 || !t.queueId);
		switch (profile) {
			case "user":
				return setNotifications(filteredTickets);
			case "supervisor":
				return setNotifications(filteredTicketsSupervisor);
			default:
				return setNotifications(tickets);
		}

	}, [tickets, queues, profile]);

	useEffect(() => {
		ticketIdRef.current = ticketIdUrl;
	}, [ticketIdUrl]);

	useEffect(() => {

		if (!user?.companyId) return;

		const userCompany = user.companyId;

		const queueIds = queues.map((q) => q.id);

		
socket.on("connect", () => {
			socket.emit("getInternalUnreadMessages", { idFrom: user.id });
			socket.emit("joinNotification")
		});


		
socket.on(`ticket-${userCompany}`, data => {
			if (data.action === "updateUnread" || data.action === "delete") {
				setNotifications(prevState => {
					const ticketIndex = prevState.findIndex(t => t.id === data.ticketId);
					if (ticketIndex !== -1) {
						prevState.splice(ticketIndex, 1);
						return [...prevState];
					}
					return prevState;
				});

				setDesktopNotifications(prevState => {
					const notfiticationIndex = prevState.findIndex(
						n => n.tag === String(data.ticketId)
					);
					if (notfiticationIndex !== -1) {
						prevState[notfiticationIndex].close();
						prevState.splice(notfiticationIndex, 1);
						return [...prevState];
					}
					return prevState;
				});
			}
		});

		
socket.on(`appMessage-${userCompany}`, data => {
			if (
				data.action === "create" &&
				!data.message.read &&
				(data.ticket.userId === user?.id || !data.ticket.userId) &&
				companyId == data.ticket.whatsapp.companyId
			) {
				if (profile === 'user' && (queueIds.indexOf(data.ticket.queue?.id) === -1 || data.ticket.queue === null)) {
					return;
				}
				if (data?.message?.fromMe === false) {
					setNotifications(prevState => {
						const ticketIndex = prevState.findIndex(t => t.id === data.ticket.id);
						if (ticketIndex !== -1) {
							prevState[ticketIndex] = data.ticket;
							return [...prevState];
						}
						return [data.ticket, ...prevState];
					});
				}
				const shouldNotNotificate =
					(data.message.ticketId === ticketIdRef.current &&
						document.visibilityState === "visible") ||
					(data.ticket.userId && data.ticket.userId !== user?.id)

				if (shouldNotNotificate) return;
				handleNotifications(data);
			}
		});

		return () => {
			//	socket.off("connect");
			//	socket.off(`ticket-${userCompany}`);
			//	socket.off(`appMessage-${userCompany}`);
		};
	}, [user, profile, queues]);

	const handleNotifications = data => {

		const { message, contact, ticket } = data;

		const isContact = message.body?.includes("BEGIN:VCARD");
		let messageBody = message.body;

		if (message?.fromMe) return;


		if (isContact) {
			let array = messageBody?.split("\n");

			array?.forEach(item => {
				const values = item?.split(":");
				if (values[0] === "FN") {
					messageBody = `${i18n.t("tickets.notification.contactBody")} ${values[1]}`;
				}
			})
		}

		const options = {
			body: `${messageBody} - ${format(new Date(), "HH:mm")}`,
			icon: contact.profilePicUrl,
			tag: ticket.id,
			renotify: true,
		};

		if (smUp) {
			const notification = new Notification(
				`${i18n.t("tickets.notification.message")} ${contact.name}`,
				options
			);

			notification.onclick = e => {
				e.preventDefault();
				window.focus();
				historyRef.current.push(`/tickets/${ticket.uuid}`);
			};

			setDesktopNotifications(prevState => {
				const notfiticationIndex = prevState.findIndex(
					n => n.tag === notification.tag
				);
				if (notfiticationIndex !== -1) {
					prevState[notfiticationIndex] = notification;
					return [...prevState];
				}
				return [notification, ...prevState];
			});
		}
	};

	const handleClick = () => {
		setIsOpen(prevState => !prevState);
	};

	const handleClickAway = () => {
		setIsOpen(false);
	};

	const NotificationTicket = ({ children }) => {
		return <div id="divticket" onClick={handleClickAway}>{children}</div>;
	};


	function renderNotification(total) {
		if (total > 0) {

		}
	}

	// Setar notificacçoes para o usuario vizualizar
	if (document.title.includes(')')) {
		var removerNotificacao = document.title.split(")");
		document.title = removerNotificacao[1]
	}
	var titulo = ""
	titulo = document.title
	if (notifications.length > 0) {
		document.title = `(${notifications.length}) ${titulo}`;
	}

	return (
		<>
			<IconButton
				onClick={handleClick}
				ref={anchorEl}
				aria-label="Open Notifications"
				color="inherit"
			>
				<Badge badgeContent={notifications.length} max={99} color="secondary" >
					{renderNotification(notifications.length)}
					<NotificationsIcon />
				</Badge>
			</IconButton>
			<div>
				<MenuPopover
					disableScrollLock
					open={isOpen}
					anchorEl={anchorEl.current}
					sx={{
						mt: 1.5,
						ml: 0.75,
						width: 320,
						'& .MuiMenuItem-root': {
							px: 1.5,
							height: 65,
							borderRadius: 0.75,
						},
					}}
					onClose={handleClickAway}

				>
					<Typography variant="h6" sx={{ p: 1.5 }}>
						{i18n.t("userType.translate.notifications")} <Typography component="span">({notifications.length})</Typography>
					</Typography>
					<Scrollbar sx={{ height: 65 * 6 }}>
						{notifications.length === 0 ? (
							<ListItem>
								<ListItemText>{i18n.t("notifications.noTickets")}</ListItemText>
							</ListItem>
						) : (
							notifications.map(ticket => (
								<NotificationTicket key={ticket.id}>
									<TicketListItem ticket={ticket} />
								</NotificationTicket>
							))
						)}
					</Scrollbar>
				</MenuPopover>
			</div>

		</>
	);
};

export default NotificationsPopOver;
