import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch"
import { Typography } from "@mui/material";

import { makeStyles } from '@mui/styles';
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import api from "../../services/api";
import ToastError from "../../toast/error/toastError";
import ToastSuccess from "../../toast/success/toastSuccess";
import Label from "../Label";

const estados = [
	{ uf: "AC", nome: "Acre" },
	{ uf: "AL", nome: "Alagoas" },
	{ uf: "AP", nome: "Amapá" },
	{ uf: "AM", nome: "Amazonas" },
	{ uf: "BA", nome: "Bahia" },
	{ uf: "CE", nome: "Ceará" },
	{ uf: "DF", nome: "Distrito Federal" },
	{ uf: "ES", nome: "Espírito Santo" },
	{ uf: "GO", nome: "Goiás" },
	{ uf: "MA", nome: "Maranhão" },
	{ uf: "MT", nome: "Mato Grosso" },
	{ uf: "MS", nome: "Mato Grosso do Sul" },
	{ uf: "MG", nome: "Minas Gerais" },
	{ uf: "PA", nome: "Pará" },
	{ uf: "PB", nome: "Paraíba" },
	{ uf: "PR", nome: "Paraná" },
	{ uf: "PE", nome: "Pernambuco" },
	{ uf: "PI", nome: "Piauí" },
	{ uf: "RJ", nome: "Rio de Janeiro" },
	{ uf: "RN", nome: "Rio Grande do Norte" },
	{ uf: "RS", nome: "Rio Grande do Sul" },
	{ uf: "RO", nome: "Rondônia" },
	{ uf: "RR", nome: "Roraima" },
	{ uf: "SC", nome: "Santa Catarina" },
	{ uf: "SP", nome: "São Paulo" },
	{ uf: "SE", nome: "Sergipe" },
	{ uf: "TO", nome: "Tocantins" },
];

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	formControl: {
		width: "100%",
		marginBottom: theme.spacing(2),
	},
	buttonWrapper: {
		position: "relative",
		marginRight: theme.spacing(2),
	},
	buttonProgress: {
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));

const CompanyUsersModal = ({ open, onClose, companyId }) => {
	const classes = useStyles();

	const [company, setCompany] = useState({
		name: "",
		cnpj: "",
		maxWhatsapps: "",
		maxUsers: "",
		email: "",
		contractor: "",
		phone: "",
		city: "",
		state: "",
		address: "",
		isActive: true
	});

	const [state, setState] = useState("");
	const [isActive, setIsActive] = useState(true);

	useEffect(() => {
		const fetchCompany = async () => {
			if (!companyId) return;
			try {
				const { data } = await api.get(`/company/${companyId}`);
				setCompany(data);
				setIsActive(data.isActive);
				setState(data.state);
			} catch (err) {
				console.error(err);
			}
		};
		fetchCompany();
	}, [companyId, open]);

	const handleClose = () => {
		onClose();
		setCompany({
			name: "",
			cnpj: "",
			maxWhatsapps: "",
			maxUsers: "",
			email: "",
			contractor: "",
			phone: "",
			city: "",
			state: "",
			address: "",
			isActive: true
		});
	};

	const handleSaveCompany = async (values) => {
		try {
			if (companyId) {
				await api.put(`/company/${companyId}`, values);
			} else {
				await api.post("/company", values);
			}
			ToastSuccess("Cadastrado com sucesso");
			handleClose();
		} catch (err) {
			console.error(err);
			ToastError(err);
		}
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setCompany(prevState => ({
			...prevState,
			[name]: value
		}));
	};


	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth="md"
			fullWidth
			scroll="body"
			BackdropProps={{
				style: {
					backdropFilter: 'blur(3px)',
					backgroundColor: 'rgba(0, 0, 30, 0.4)',
				},
			}}
		>
			<DialogTitle id="form-dialog-title">
				<Box sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between'
				}}>
					<p>{companyId ? "Editar Empresa" : "Adicionar Empresa"}</p>
					<Label
						color={!isActive ? 'error' : 'success'}
						sx={{ textTransform: 'uppercase', position: 'absolute', top: 24, right: 24 }}
					>
						{!isActive ? 'Bloqueada' : 'Ativa'}
					</Label>
				</Box>
			</DialogTitle>
			<Formik
				enableReinitialize={true}
				initialValues={company}
				validationSchema={Yup.object({
					name: Yup.string().required("O nome da empresa é obrigatório"),
					cnpj: Yup.string().required("O CNPJ é obrigatório"),
					maxWhatsapps: Yup.string().required("O máximo de WhatsApps é obrigatório"),
					maxUsers: Yup.string().required("O máximo de usuários é obrigatório"),
					email: Yup.string().email("E-mail inválido").required("O e-mail é obrigatório"),
					contractor: Yup.string().required("O responsável é obrigatório"),
					phone: Yup.string().required("O contato é obrigatório"),
					city: Yup.string().required("A cidade é obrigatória"),
					address: Yup.string().required("O endereço é obrigatório"),
				})}
				onSubmit={(values, actions) => {
					values = { ...values, state: state, isActive: isActive };
					handleSaveCompany(values);
					actions.setSubmitting(false);
				}}
			>
				{({ isSubmitting, setFieldValue }) => (
					<Form>
						<DialogContent>
							<div className={classes.formControl}>
								<Field
									sx={{ width: '50%', padding: '0px 5px' }}
									as={TextField}
									label="Empresa"
									type="text"
									name="name"
									required
									variant="outlined"
									margin="dense"
								/>
								<Field
									sx={{ width: '40%', padding: '0px 5px' }}
									as={TextField}
									label="CNPJ"
									type="text"
									name="cnpj"
									required
									variant="outlined"
									margin="dense"
								/>
								{companyId && (
									<Box sx={{ width: '10%', padding: '0px 5px' }}>
										<FormControlLabel
											labelPlacement="start"
											control={
												<Switch
													checked={!isActive}
													onChange={() => setIsActive(!isActive)}
												/>
											}
											label={<><Typography variant="subtitle2">Bloquear</Typography></>
											}
											sx={{ flexDirection: 'column-reverse' }}
										/>
									</Box>
								)}
							</div>
							<div className={classes.formControl}>
								<Field
									sx={{ width: '40%', padding: '0px 5px' }}
									as={TextField}
									label="Responsável"
									type="text"
									name="contractor"
									required
									variant="outlined"
									margin="dense"
								/>
								<Field
									sx={{ width: '30%', padding: '0px 5px' }}
									as={TextField}
									label="Máximo de WhatsApps"
									type="number"
									name="maxWhatsapps"
									required
									variant="outlined"
									margin="dense"
								/>
								<Field
									sx={{ width: '30%', padding: '0px 5px' }}
									as={TextField}
									label="Máximo de Usuários"
									type="number"
									name="maxUsers"
									required
									variant="outlined"
									margin="dense"
								/>
							</div>
							<div className={classes.formControl}>
								<Field
									sx={{ width: '50%', padding: '0px 5px' }}
									as={TextField}
									label="Contato"
									type="text"
									name="phone"
									required
									variant="outlined"
									margin="dense"
								/>
								<Field
									sx={{ width: '50%', padding: '0px 5px' }}
									as={TextField}
									label="E-mail"
									type="email"
									name="email"
									required
									variant="outlined"
									margin="dense"
								/>
							</div>
							<div className={classes.formControl}>
								<Field
									sx={{ width: '50%', padding: '0px 5px' }}
									as={TextField}
									label="Endereço"
									type="text"
									name="address"
									required
									variant="outlined"
									margin="dense"
								/>
								<Field
									sx={{ width: '30%', padding: '0px 5px' }}
									as={TextField}
									label="Cidade"
									type="text"
									name="city"
									required
									variant="outlined"
									margin="dense"
								/>
								<FormControl sx={{ width: '20%', padding: '8px 5px 0px 5px' }}>
									<InputLabel id="demo-simple-select-label-state">Estado</InputLabel>
									<Field
										as={Select}
										labelId="demo-simple-select-label-state"
										id="demo-simple-select-state"
										value={state}
										label="Estado"
										name="state"
										onChange={(e) => setState(e.target.value)}
										required
									>
										{estados.map(estado => (
											<MenuItem key={estado.uf} value={estado.uf}>
												{estado.nome}
											</MenuItem>
										))}
									</Field>
								</FormControl>

							</div>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={handleClose}
								color="secondary"
								sx={{ margin: '0px 5px' }}
								variant="outlined">
								Cancelar
							</Button>
							<div className={classes.buttonWrapper}>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									sx={{ margin: '0px 5px' }}
									variant="contained"
								>
									{companyId ? "Editar" : "Adicionar"}
								</Button>
								{isSubmitting && (
									<CircularProgress size={24} className={classes.buttonProgress} />
								)}
							</div>
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default CompanyUsersModal;

