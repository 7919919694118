import React, { useEffect, useState } from "react";

import { Box, Button, Checkbox, CircularProgress, Container, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialOceanic } from 'react-syntax-highlighter/dist/esm/styles/prism';
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  formContainer: {
    maxWidth: 700,
    margin: "auto",
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
  responseBox: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
  },
}));

const MessagesAPI = () => {
  const classes = useStyles();
  const [token, setToken] = useState('');
  const [number, setNumber] = useState('');
  const [body, setBody] = useState('');
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [connection, setConnection] = useState('');
  const [queue, setQueue] = useState('');
  const [user, setUser] = useState('');
  const [createTicket, setCreateTicket] = useState(false);
  const [statusTicket, setStatusTicket] = useState('');
  const [saveContact, setSaveContact] = useState(false);

  const [connectionsArray, setConnectionsArray] = useState([]);
  const [queuesArray, setQueuesArray] = useState([]);
  const [usersArray, setUsersArray] = useState([]);
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 1500); // Reset copied state after 1.5 seconds
  };


  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get('/api/api_data');
        console.info(data)
        setUsersArray(data.users);
        setConnectionsArray(data.whatsapps);
      } catch (err) {
        console.error(err)
      }
    })();
  }, []);

  const request = {
    contact: {
      number: number,
      create: saveContact
    },
    message: {
      body: body,
      file: file?.name
    },
    ticket: {
      create: createTicket,
      assigned: {
        status: statusTicket,
        user: user,
        queue: queue
      }
    }
  };

  console.info(request);

  const handleSubmit = async () => {

    setLoading(true);
    try {

      const requestData = {
        token: token,
        contact: {
          number: number,
          create: saveContact
        },
        message: {
          body: body,
          file: file
        },
        ticket: {
          create: createTicket,
          assigned: {
            status: statusTicket,
            user: user,
            queue: queue
          }
        }
      }

      const formData = new FormData();

      formData.append('data', JSON.stringify(requestData));

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/messages/send/${token}`, formData);

      setResponse(response.data);

    } catch (error) {
      console.error('Error sending message:', error);
      setResponse({ error: 'Error sending message' });
    }
    setLoading(false);
  };


  const handleConnections = (id) => {

    const filter = connectionsArray.find(objeto => objeto.id === id);

    setQueuesArray(filter.queues);
    setToken(filter.apiToken);
    setConnection(id)
  };

  const dataCurl = `curl --location '${process.env.REACT_APP_BACKEND_URL}/api/messages/send/${token}' \\
  --header 'Content-Type: application/json' \\
  --data '${JSON.stringify({ data: request }, null, 2).replace(/\n/g, "\n\t")}'`;

  return (
    <Container maxWidth={false} className={classes.root}>
      <Typography variant="h4" gutterBottom>
        Envio de Mensagens via API
      </Typography>  <div>
        <Grid container spacing={2} className={classes.formContainer}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined" className={classes.inputField}>
              <InputLabel>Conexão</InputLabel>
              <Select
                value={connection}
                onChange={(e) => handleConnections(e.target.value)}
                label="Conexão"
              >
                {connectionsArray.map((w) => (
                  <MenuItem value={w.id}>{w.name}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{token}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Número"
              fullWidth
              variant="outlined"
              className={classes.inputField}
              value={number}
              onChange={(e) => setNumber(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Mensagem"
              fullWidth
              multiline
              rows={2}
              maxRows={5}
              variant="outlined"
              className={classes.inputField}
              value={body}
              onChange={(e) => setBody(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => setFile(e.target.files[0])}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={createTicket} onChange={(e) => setCreateTicket(e.target.checked)} />}
              label="Criar Ticket"
            />
          </Grid>
          {createTicket &&
            <>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth variant="outlined" className={classes.inputField}>
                  <InputLabel>Fila</InputLabel>
                  <Select
                    value={queue}
                    onChange={(e) => setQueue(e.target.value)}
                    label="Fila"
                    disabled={!createTicket}
                  >
                    <MenuItem value="">
                      <em>Sem fila</em>
                    </MenuItem>
                    {queuesArray.map((q) => (
                      <MenuItem value={q.id}>{q.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={5}>
                <FormControl fullWidth variant="outlined" className={classes.inputField}>
                  <InputLabel>Transferir para usuário</InputLabel>
                  <Select
                    value={user}
                    onChange={(e) => setUser(e.target.value)}
                    label="Transferir para usuário"
                    disabled={!createTicket}
                  >
                    <MenuItem value=""><em>Sem usuario</em></MenuItem>
                    {usersArray.map((u) => (
                      <MenuItem value={u.id}>{u.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth variant="outlined" className={classes.inputField}>
                  <InputLabel>Status do Ticket</InputLabel>
                  <Select
                    value={statusTicket}
                    onChange={(e) => setStatusTicket(e.target.value)}
                    label="Status do Ticket"
                    disabled={!createTicket}
                  >
                    <MenuItem disabled={!user} value="open">{user ? `Aberto` : `Aberto (Selecione um usuario)`}</MenuItem>
                    <MenuItem value="closed">Fechado</MenuItem>
                    <MenuItem value="pending">Pendente</MenuItem>
                    <MenuItem disabled={!user} value="archived">{user ? `Arquivado` : `Arquivado (Selecione um usuario)`}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </>
          }
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={saveContact} onChange={(e) => setSaveContact(e.target.checked)} />}
              label="Salvar Contato no Sistema"
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={loading}
              className={classes.submitButton}
            >
              {loading ? <CircularProgress size={24} /> : "Enviar"}
            </Button>
          </Grid>
        </Grid>
      </div>
      {request && (
        <Box mt={4} className={classes.responseBox}>
          <Typography variant="h6" gutterBottom>
            Objeto de envio:
          </Typography>
          <Box>
            <CopyToClipboard text={dataCurl}
              onCopy={handleCopy}>
              <Button variant="outlined" color="primary">
                {copied ? 'Copiado!' : 'Copiar'}
              </Button>
            </CopyToClipboard>
          </Box>
          <SyntaxHighlighter language="bash" style={materialOceanic} customStyle={{ fontSize: '14px' }}>
            {dataCurl}
          </SyntaxHighlighter>
        </Box>
      )}
      {response && (
        <Box mt={4} className={classes.responseBox}>
          <Typography variant="h6" gutterBottom>
            Resposta da API:
          </Typography>
          <SyntaxHighlighter language="json" style={materialOceanic} customStyle={{ fontSize: '14px' }}>
            {JSON.stringify(response, null, 2)}
          </SyntaxHighlighter>
        </Box>
      )}
    </Container>
  );
};

export default MessagesAPI;
