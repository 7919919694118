import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Autocomplete, {
	createFilterOptions,
} from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { makeStyles } from 'tss-react/mui';

import useQueues from "../../hooks/useQueues";
import api from "../../services/api";
import ToastError from "../../toast/error/toastError";
import { i18n } from "../../translate/i18n";
import ButtonWithSpinner from "../ButtonWithSpinner";

const useStyles = makeStyles()((theme) => {
	return {
		maxWidth: {
			width: "100%",
		},
	}
});

const filterOptions = createFilterOptions({
	trim: true,
});

const TransferTicketPendingModalCustom = ({ modalOpen, onClose, ticket }) => {

	const history = useHistory();
	const [options, setOptions] = useState([]);
	const [queues, setQueues] = useState([]);
	const [allQueues, setAllQueues] = useState([]);
	const [loading, setLoading] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);
	const [selectedQueue, setSelectedQueue] = useState('');
	const [searchParam, setSearchParam] = useState('');

	const { classes } = useStyles();
	const { findAll: findAllQueues } = useQueues();

	useEffect(() => {
		const loadQueues = async () => {
			const list = await findAllQueues();
			setAllQueues(list);
			setQueues(list);
		}
		loadQueues();
	}, []);

	useEffect(() => {
		if (!modalOpen || selectedQueue === '') {
			setLoading(false);
			return;
		}
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchUsers = async () => {
				try {
					const { data } = await api.get(`/users-transfer/${selectedQueue}`);
					setOptions(data.users);
					setLoading(false);
				} catch (err) {
					setLoading(false);
					ToastError(err);
				}
			};
			fetchUsers();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [selectedQueue, modalOpen]);

	const handleClose = () => {
		onClose();
		setSelectedUser(null);
	};

	const handleSaveTicket = async e => {
		e.preventDefault();
		if (!ticket.id) return;
		setLoading(true);
		try {
			let data = {};

			if (selectedUser) {
				data.userId = selectedUser.id
			}


			if (selectedQueue && selectedQueue !== null) {
				data.queueId = selectedQueue

				if (!selectedUser) {
					data.status = 'pending';
					data.userId = null;
				} else {
					data.status = 'open';
				}

			}
			const res = await api.put(`/tickets/${ticket.id}`, data);
			setLoading(false);
			console.info("Puxando pagina ticket na linha: 130")
			history.push(`/tickets`);
		} catch (err) {
			setLoading(false);
			ToastError(err);
		}
	};


	return (
		<Dialog
			open={modalOpen}
			onClose={handleClose}
			maxWidth="lg"
			scroll="body"
			BackdropProps={{
				style: {
					backdropFilter: 'blur(3px)',
					backgroundColor: 'rgba(0, 0, 30, 0.4)',
				},
			}}
		>
			<form onSubmit={handleSaveTicket}>

				<DialogTitle id="form-dialog-title">
					{i18n.t("transferTicketModal.title")}
				</DialogTitle>

				<DialogContent dividers>
					<FormControl variant="outlined" className={classes.maxWidth} size="small" style={{ width: 300, marginBottom: 20 }}>
						<InputLabel>{i18n.t("transferTicketModal.fieldQueueLabel")}</InputLabel>
						<Select
							size="small"
							value={selectedQueue}
							onChange={(e) => setSelectedQueue(e.target.value)}
							label={i18n.t("transferTicketModal.fieldQueuePlaceholder")}
						>
							<MenuItem value={''}>&nbsp;</MenuItem>
							{queues.map((queue) => (
								<MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
							))}
						</Select>
					</FormControl>

					<Autocomplete
						disabled={selectedQueue === ''}
						size="small"
						style={{ width: 300, marginBottom: 20 }}
						getOptionLabel={option => `${option.name}`}
						onChange={(e, newValue) => {
							setSelectedUser(newValue);
						}}
						options={options}
						filterOptions={filterOptions}
						freeSolo
						autoHighlight
						noOptionsText={i18n.t("transferTicketModal.noOptions")}
						loading={loading}
						renderInput={params => (
							<TextField
								size="small"
								{...params}
								label={selectedQueue === '' ? i18n.t("transferTicketModal.fieldLabelSelect") : i18n.t("transferTicketModal.fieldLabel")}
								variant="outlined"
								autoFocus
								onChange={e => setSearchParam(e.target.value)}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{loading ? (
												<CircularProgress color="inherit" size={20} />
											) : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/>


				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						disabled={loading}
						variant="outlined"
					>
						{i18n.t("transferTicketModal.buttons.cancel")}
					</Button>
					<ButtonWithSpinner
						disabled={selectedQueue === ''}
						variant="contained"
						type="submit"
						color="primary"
						loading={loading}
					>
						{i18n.t("transferTicketModal.buttons.ok")}
					</ButtonWithSpinner>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default TransferTicketPendingModalCustom;
