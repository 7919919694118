const messages = {
  en: {
    translations: {
      signup: {
        title: "Sign up",
        toasts: {
          success: "User created successfully! Please login!",
          fail: "Error creating user. Check the reported data.",
        },
        form: {
          name: "Name",
          email: "Email",
          password: "Password",
        },
        buttons: {
          submit: "Register",
          login: "Already have an account? Log in!",
        },
      },
      recovery: {
        title: "Recovery account",
        formTitle: "UniChat password recovery",
        details: "Enter your email to receive the access code.",
        details2: "Fill in the two fields to change the password.",
        passwordDetails: "Enter your new password to proceed.",
        emailSend: "You will receive an email with the recovery code.",
        form: {
          email: "Email",
          code: "Recovery code",
          password: "Password",
          rePassword: "Repeat password",
        },
        buttons: {
          submit: "Send code",
          next: "Continue",
          newCode: "Generate new code",
          change: "Change password",
        },
      },
      login: {
        title: "Login",
        formTitle: "Sign in to UniChat",
        welcome: "Hi, Welcome Back",
        details: "Enter your details below.",
        form: {
          email: "Email",
          password: "Password",
        },
        buttons: {
          submit: "Enter",
          register: "Don't have an account? Register!",
          recovery: "Forgot your password?",
          login: "Back to login",
          notUser: "Don't have an account?",
          demo: "Request a demo test!",
        },
      },
      weekDays: {
        title: "Days",
        sunday: "Sunday",
        monday: "Monday",
        tuesday: "Tuesday",
        wednesday: "Wednesday",
        thursday: "Thursday",
        friday: "Friday",
        saturday: "Saturday",
        avoidHoliday: "Only working day",
      },

      salutation: {
        goodmorning: "Good Morning",
        goodafternoon: "Good Afternoon",
        goodnight: "Good night",
        gooddawn: "Good Morning"
      },
      companyList: {
        cancel: "Cancel",
        edit: "Edit",
        add: "Add",
        emailDefault: "Email Default",
        maxWhatsapp: "N° Whatsapps",
        maxUsers: "N° Users",
        cnpj: "CNPJ",
        company: "Company",
        editCompany: "Edit Company",
        addCompany: "Add Company"
      },
      tagsList: {
        cancel: "Cancel",
        edit: "Edit",
        add: "Create",
        name: "Name",
        color: "Color",
        type: "Tag type",
        editTag: "Edit Tag",
        addTag: "Create Tag"
      },
      userType: {
        translate: {
          nameWhatsapp: "Whatsapp name",
          infoReload: "The page will reload whenever you edit the user itself.",
          user: "Attendant",
          supervisor: "Supervisor",
          admin: "Administrator",
          superUser: "Super User",
          sendPhoto: "Send Photo",
          company: "Company",
          empty: "Empty",
          searchUsers: "Search Users",
          searchContacts: "Search Contacts",
          name: "Name",
          number: "Number",
          email: "Email",
          type: "Type",
          action: "Actions",
          result: "Results",
          page: "From",
          of: "to",
          notifications: 'Notifications',
          resultsPerPage: "Results per Page",
          selectQueue: "Select a queue",
          send: "Send",
          errorPermissionNotifications: "You don't have notifications permissions!",
          newMessageUser: "New message from ",
          modifyDefinitionsBot: "Use Queues in List?",
          changeSuccess: "You change to ",
          listSuccess: "list",
          defaultSuccess: "Default",
          openWppWeb: "Open in Whatsapp Web",
          openCall: "Call to Number",
          config: "Theme Options",
          theme: "Theme",
          colors: "Colors",
          notNotifications: "Browser notifications disabled!",
          notUsers: "Add users to have access to the internal chat.",
          sayHello: "Say hello to your new contact!",
          you: "You",
          notQuickAnswers: "Not quick answers",
          hello: "Hello",
          needHelp: "Need help?",
          finish: "Finish",
          finishTicket: "Finish Ticket",
          wasResolved: "Was Resolved?",
          reason: "Reason for contact",
          yes: "Yes",
          no: "No",
          closed: "Closed",
          photo: "Photo",
          video: "Video",
          audio: "Audio",
          file: 'Archive'
        }
      },
      auth: {
        toasts: {
          success: "Login successfully!",
        },
      },
      dashboard: {
        tickets: {
          ticketsRealTime: "Report - ",
          ticketsOpen: "Open",
          ticketsPending: "Pending",
          ticketsClosed: "Closeds",
          ticketsTotal: "Total",
        },
        charts: {
          perDay: {
            title: "Tickets",
          },
        },
      },
      companies: {
        pages: {
          companies: 'Companies',
          overview: {
            name: 'Overview',
            companies: 'Companies',
            users: 'Users',
            connections: 'Connections',
            ram: {
              usage: 'RAM usage',
              usageTotal: 'Used'
            },
            system: {
              systemSpec: 'Server specifications',
              storage: 'Storage',
              so: 'Operating System'
            }
          },
          report: 'Report',
        },
        buttons: {
          add: 'Add company',
        },
        deleteInfo: 'By deleting the company, you will lose all registration data and the history of this company and its users.',
        list: {
          companies: 'Companies',
          overview: 'Overview',
          systemReport: 'Usage report'
        },
        table: {
          name: 'Name',
          cnpj: 'Cnpj',
          connections: 'N° Connections',
          users: 'N° Users',
          status: 'Status',
          action: 'Actions'
        },
        confirmations: {
          delete: 'Do you really want to delete the company'
        }
      },
      connections: {
        title: "Connections",
        toasts: {
          deleted: "Connection deleted sucessfully!",
          copied: "Token copied!"
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage: "Are you sure? It cannot be reverted.",
          disconnectTitle: "Disconnect",
          disconnectMessage: "Are you sure? You'll need to read QR Code again.",
        },
        buttons: {
          add: "Add Connection",
          disconnect: "Disconnect",
          tryAgain: "Try Again",
          qrcode: "QR CODE",
          newQr: "New QR CODE",
          connecting: "Connectiing",
        },
        toolTips: {
          disconnected: {
            title: "Failed to start session",
            content:
              "Make sure your cell phone is connected to the internet and try again, or request a new QR Code",
          },
          qrcode: {
            title: "Waiting for QR Code read",
            content:
              "Click on 'QR CODE' button and read the QR Code with your cell phone to start session",
          },
          connected: {
            title: "Connection established",
          },
          timeout: {
            title: "Connection with cell phone has been lost",
            content:
              "Make sure your cell phone is connected to the internet and WhatsApp is open, or click on 'Disconnect' button to get a new QRcode",
          },
        },
        table: {
          channel: 'Channel',
          name: "Name",
          status: "Status",
          lastUpdate: "Last Update",
          default: "Default",
          autoClose: "Automatic closing",
          autoCloseTimeOut: "Time limit",
          autoCloseMessage: "Message on closing",
          actions: "Actions",
          session: "Session",
        },
        channel: {
          name: 'Integration channel',
          whatsapp: 'Whatsapp',
          telegram: 'Telegram',
          instagram: 'Instagram',
          messenger: 'Messenger'
        },
      },
      whatsappModal: {
        title: {
          add: "Add WhatsApp",
          edit: "Edit WhatsApp",
        },
        autoClose: 'Finalize ticket for lack of interaction',
        useTyping: 'Use "Typing" on send message',
        insistAnswering: 'Resend queue options',
        autoCloseTime: 'Time Limit',
        autoCloseMessage: 'Message when closing time limit',
        channel: {
          name: 'Integration channel',
          whatsapp: 'Whatsapp',
          telegram: 'Telegram',
          instagram: 'Instagram',
          messenger: 'Messenger'
        },
        form: {
          name: "Name",
          default: "Default",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "WhatsApp saved successfully.",
      },
      qrCode: {
        message: "Read QrCode to start the session",
      },
      contacts: {
        title: "Contacts",
        toasts: {
          deleted: "Contact deleted sucessfully!",
        },
        searchPlaceholder: "Search ...",
        confirmationModal: {
          deleteTitle: "Delete",
          importTitlte: "Import contacts",
          deleteMessage:
            "Are you sure you want to delete this contact? All related tickets will be lost.",
          importMessage: "Do you want to import all contacts from the phone?",
        },
        buttons: {
          import: "Import Contacts",
          add: "Add Contact",
          block: "Block/unblock Contact",
          filterBlocked: "Filter Blocked",
          removeFilter: "Remove Filter",
        },
        table: {
          name: "Name",
          whatsapp: "WhatsApp",
          email: "Email",
          actions: "Actions",
        },
      },
      contactModal: {
        title: {
          add: "Add contact",
          edit: "Edit contact",
        },
        form: {
          mainInfo: "Contact details",
          extraInfo: "Additional information",
          name: "Name",
          number: "Whatsapp number",
          email: "Email",
          extraName: "Field name",
          extraValue: "Value",
        },
        buttons: {
          addExtraInfo: "Add information",
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Contact saved successfully.",
      },
      quickAnswersModal: {
        title: {
          add: "Add Quick Reply",
          edit: "Edit Quick Answer",
        },
        form: {
          shortcut: "Shortcut",
          message: "Quick Reply",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Quick Reply saved successfully.",
      },
      queueModal: {
        title: {
          add: "Add queue",
          edit: "Edit queue",
        },
        form: {
          name: "Name",
          color: "Color",
          greetingMessage: "Greeting Message",
          closedMessage: "Closed Message",
          farewellMessage: "Farewell Message",
          messageAccepting: "message on accept",
          description: "Description of Queue",
          token: "Token",
          tabs: {
            information: "Information",
            schedules: "Schedules",
          },
          schedules: {
            monday: "Monday",
            tuesday: "Tuesday",
            wednesday: "Wednesday",
            thursday: "Thursday",
            friday: "Friday",
            saturday: "Saturday",
            sunday: "Sunday",
            interval: "Interval",
          }
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
          options: "Markups",
        },
        markups: {
          queue: "Queue",
          userName: "User name",
        },
        chatbot: {
          use: 'Use chatbot on this queue',
          select: 'Select Chatbot'
        }
      },
      userModal: {
        title: {
          add: "Add user",
          edit: "Edit user",
        },
        form: {
          name: "Name",
          email: "Email",
          password: "Password",
          profile: "Profile",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "User saved successfully.",
      },
      chat: {
        noTicketMessage: "Select a ticket to start chatting.",
      },
      ticketsManager: {
        buttons: {
          newTicket: "New",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Queues",
      },
      tickets: {
        toasts: {
          deleted: "The ticket you were on has been deleted.",
        },
        notification: {
          message: "Message from",
          contactBody: "Contact:",
        },
        tabs: {
          open: {
            title: "Inbox",
            open: "attending",
            pending: "Waiting",
          },
          closed: { title: "Resolved" },
          search: { title: "Search" },
        },
        search: {
          placeholder: "Search tickets and messages.",
        },
        buttons: {
          showAll: "All",
        },
      },
      transferTicketModal: {
        title: "Transfer Ticket",
        fieldLabel: "Type to search for users",
        fieldQueueLabel: "Transfer to queue",
        fieldQueuePlaceholder: "Select queue",
        noOptions: "No user found with this name",
        buttons: {
          ok: "Transfer",
          cancel: "Cancel",
        },
      },
      ticketsList: {
        pendingHeader: "Queue",
        assignedHeader: "Working on",
        noTicketsTitle: "Nothing here!",
        noTicketsMessage: "No tickets found with this status or search term.",
        buttons: {
          accept: "Accept",
        },
      },
      newTicketModal: {
        title: "Create Ticket",
        newNumber: "New phone number",
        fieldLabel: "Type to search for a contact",
        add: "Add",
        initAdd: "Create ticket now",
        searchContact: "Search contact",
        newContact: "New number",
        info: {
          searchContactInfo: "Search for contacts saved in your database.",
          newContactInfo: "Initiate contact directly with the number without adding it.",
        },
        buttons: {
          ok: "Save",
          cancel: "Cancel",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Dashboard",
          connections: "Connections",
          tickets: "Tickets",
          contacts: "Contacts",
          quickAnswers: "Quick Answers",
          queues: "Queues",
          administration: "Administration",
          admin: "Admin",
          folder: "File Manager",
          users: "Users",
          settings: "Settings",
          company: "Companies",
          report: "General report",
          keywords: "Keywords",
          massMessaging: "Mass Messaging",
          messagesAPI: "API",
        },
        appBar: {
          user: {
            profile: "Profile",
            logout: "Logout",
          },
        },
      },
      messagesAPI: {
        title: 'API',
        textMessage: {
          number: 'Número',
          body: 'Mensagem',
          token: 'Token cadastrado',
        },
        mediaMessage: {
          number: 'Número',
          body: 'Nome do arquivo',
          media: 'Arquivo',
          token: 'Token cadastrado',
        }
      },
      notifications: {
        noTickets: "No notifications.",
      },
      queues: {
        title: "Queues",
        table: {
          name: "Name",
          color: "Color",
          type: "Type",
          greeting: "Greeting message",
          messageAccepting: "Message on accept",
          actions: "Actions",
        },
        buttons: {
          add: "Add queue",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage:
            "Are you sure? It cannot be reverted! Tickets in this queue will still exist, but will not have any queues assigned.",
        },
      },
      tags: {
        title: "Tags",
        table: {
          name: "Name",
          color: "Color",
          actions: "Actions",
        },
        buttons: {
          add: "Add tag",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage:
            "Are you sure? This action cannot be reversed! Calls with this tag will be untagged again.",
        },
      },
      queueSelect: {
        inputLabel: "Queues",
      },
      massMessaging: {
        title: "Mass Messaging",
        table: {
          shortcut: "Title of Mass Messaging",
          message: "Message to be sent",
          actions: "Actions",
        },
        buttons: {
          play: 'Play',
          pause: 'Pause',
          edit: 'Edit',
          delete: 'Delete',
        },
        actions: {
          startIn: 'Start In',
          endIn: 'End In',
          intervalStart: 'Interval Start',
          intervalEnd: 'Interval End',
        },
        contacts: "Contacts",
        file: "File",
        edit: "Edit Mass Messaging",
        add: "Create Mass Messaging",
        buttonsOp: {
          add: "Create Mass Messaging",
          example: "Contacts file",
        },
        total: "Total: ",
        success: "Success: ",
        error: "Error: ",
        days: "Estimate: ",
        selectAll: "Select All"
      },
      massMessagings: {
        table: {
          shortcut: "Name",
          message: "Message",
          date: "Start and end date",
          interval: "Interval",
          period: "Period",
          daysOfWeek: "Days Of Week",
          status: "Status",
          progress: "Progress",
          actions: "Actions",
        },
        wpp: {
          name: 'Whatsapp',
          connected: 'Connected',
          disconnect: 'Disconnected'
        },
        buttons: {
          play: 'Play',
          pause: 'Pause',
          edit: 'Edit',
          delete: 'Delete',
        },
        period: {
          from: "From",
          of: "to",
          daytime: "Daytime",
          nocturnal: "Nocturnal"
        },
        status: {
          all: 'All',
          waiting: "Waiting",
          started: "Started",
          finished: "Finished",
          waitingTime: "Waiting for time",
          paused: "Paused",
        },
        toasts: {
          deleted: "Disparo excluída com sucesso.",
        },
        searchPlaceholder: "Search...",
        confirmationModal: {
          deleteTitle:
            "Are you sure you want to delete the message trigger: ",
          deleteMessage: "This action cannot be reversed and you will lose progress if it has already started.",
        },
      },
      quickAnswers: {
        title: "Quick Answers",
        table: {
          shortcut: "Shortcut",
          message: "Quick Reply",
          actions: "Actions",
        },
        buttons: {
          add: "Add Quick Reply",
        },
        toasts: {
          deleted: "Quick Reply deleted successfully.",
        },
        searchPlaceholder: "Search...",
        confirmationModal: {
          deleteTitle: "Are you sure you want to delete this Quick Reply: ",
          deleteMessage: "This action cannot be undone.",
        },
      },
      chatbot: {
        title: "Chatbot",
        table: {
          name: "Name",
          updatedAt: "Last update",
          actions: "Actions",
        },
        buttons: {
          add: "Create chatbot",
        },
        toasts: {
          deleted: "Chatbot deleted successfully.",
        },
        searchPlaceholder: "Search...",
        confirmationModal: {
          deleteTitle:
            "Are you sure you want to delete the chatbot: ",
          deleteMessage: "This action cannot be reversed.",
        },
        editFlow: {
          loading: 'Loading flow...',
          save: 'Saving flow...',
          type: {
            saveResponse: 'Save answer',
            conditional: 'Conditional',
            finished: 'End'
          },
          step: {
            start: 'Flow start.',
            save: 'Save answer.',
            conditional: 'Define a question or continuations.',
            endToQueue: 'Ends the flow by transferring to the sector.',
            end: 'Ends the flow by closing the ticket.'
          },
          options: {
            edit: 'Edit card.',
            delete: 'Delete card.',
          },
          buttons: {
            save: "Save flow",
            cancel: "Cancel edit",
            addFinish: "Add ending",
            addConditional: "Add conditional",
          },
        },
        menu: {
          options: {
            end: 'Finish',
            conditional: 'Conditional',
            finalizeService: 'End ticket',
            saveResponse: 'Save response',
            transferTo: 'Transfer to',
            responseAfterCapture: 'Response after capture',
            endFlow: 'End flow',
            message: 'Message',
            title: 'Title',

          },
          buttons: {
            save: 'Save',
            cancel: 'Cancel'
          }
        }
      },
      keywords: {
        title: "Keywords",
        placeholder: 'Type and press "ENTER"'
      },
      reportTickets: {
        title: "Ticket Report",
        table: {
          name: "Contact name",
          number: "Contact number",
          createdOn: "Contact date",
          updatedOn: "Last update",
          status: "Current status",
          userAtt: "Assigned User",
          notUserAtt: "No User Assigned",
          actions: "Actions",
        },
        buttons: {
          export: "Export All in Excel",
          filter: "Filter",
          viewTicketMsg: "View conversation of this ticket",
        },
        status: {
          open: 'Open',
          pending: 'Pending',
          closed: 'Closed',
          all: 'All'
        },
        excel: {
          error: 'There are no tickets to generate a report.',
          archive: 'Report',
          name: 'Name',
          number: 'Number',
          createdAt: 'Contact date',
          updatedAt: 'Date of last interaction',
          status: 'Status',
          protocol: 'Protocol',
          assignedUser: 'Assigned User',
          queue: 'Queue',
          rating: 'Service evaluation',
          notRating: 'Concluded without service evaluation'
        },
        searchPlaceholder: "Search...",
      },
      ChatbotModal: {
        title: {
          add: "Create chatbot",
          edit: "Edit name",
        },
        form: {
          name: "Name",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Chatbot created successfully.",
      },
      users: {
        title: "Users",
        table: {
          name: "Name",
          email: "Email",
          profile: "Profile",
          actions: "Actions",
        },
        buttons: {
          add: "Add user",
        },
        toasts: {
          deleted: "User deleted sucessfully.",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage:
            "All user data will be lost. Users' open tickets will be moved to queue.",
        },
      },
      settings: {
        success: "Settings saved successfully.",
        title: "Settings",
        settings: {
          userCreation: {
            name: "User creation",
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            },
          },
          typeQueue: {
            name: "Queues listing",
            options: {
              default: "Default",
              list: "List",
              buttons: "Buttons",
            },
            optionsInfo: {
              default: "Default menu",
              list: "Menu in list",
              buttons: "Menu in buttons",
            }
          },
          answerInGroups: {
            name: 'Answer in groups',
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            },
          },
          serviceRating: {
            name: "Service rating",
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            },
            obs: "On activation, a message will be sent to the client to rate the grade of the service provided."
          },
          messageRating: {
            name: "Service rating (Message)",
            obs: `This message will be displayed after completing the service. Its function is to request the attendance note from the user.`
          },
          attendantViewNoQueue: {
            name: "Attendant view without sector",
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            },
          },
          ticketTime: {
            name: "Minimum time to create new ticket",
            obs: "This field is used to configure the minimum time to generate a new ticket for the same customer who contacted us recently.",
            time: "Time in hours"
          },
          keywords: {
            name: "Use keywords",
            obs: "This field is used to identify words after the ticket has been completed, preventing the opening of new tickets and automatically answering customers.",
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            },
          },
          periodTime: {
            title: 'Periods of work',
            start: 'Start',
            end: 'End'
          },
          ticketsOrder: {
            name: "Ordenação dos Tickets",
            options: {
              default: "New messages first (Default)",
              desc: "New tickets first",
              asc: "Old tickets first",
            },
          },
        },
      },
      messagesList: {
        header: {
          assignedTo: "Assigned to:",
          buttons: {
            return: "Return",
            delete: "Delete Service",
            transfer: "Transfer Service",
            presence: "Make In-person",
            notpresence: "Make Non-personal",
            resolve: "Resolve",
            archiveMsg: "Archive",
            markupResolved: 'Mark as Resolved',
            removeArchiveMsg: "Move to Serving",
            reopen: "Reopen",
            accept: "Accept",
            archive: "Archive",
            moveArchive: "Resolve Again",
          },
          buttonsInfo: {
            return: "Return the service to the waiting queue.",
            delete: "Permanently delete the service, removing all its associated records from the database.",
            transfer: "Transfer the service to another attendant or to a different sector.",
            presence: "Turn the service into in-person, indicating a potential technical visit or meeting.",
            notpresence: "Indicate that the service is not in-person, that is, it's virtual or over the phone.",
            resolve: "Mark the service as resolved, completing the process.",
            archiveMsg: "Archive the message, removing it from the main view but keeping it in the history.",
            markupResolved: "Indicate that the customer's issue or query has been successfully addressed and resolved.",
            removeArchiveMsg: "Move the service from the archive back to the 'Serving' status.",
            reopen: "Reopen a previously closed or resolved service.",
            accept: "Accept a service that was waiting in the queue.",
            archive: "Archive the service, removing it from the main view, but keeping it in the history for future reference.",
            moveArchive: "Mark the service as resolved again, after having reopened it previously."
          },
        },
        others: {
          editedMessage: "Edited"
        }
      },
      messagesInput: {
        placeholderOpen: "Type a message or / for quick answers",
        placeholderOpenModile: "Type a message",
        placeholderNotepad: "Write your notes...",
        placeholderClosed: "Reopen or accept this ticket to send a message.",
        signMessage: "Sign",
      },
      contactDrawer: {
        header: "Contact details",
        buttons: {
          edit: "Edit contact",
          block: "block Contact",
          unblock: "Unblock Contact",
        },
        extraInfo: "Other information",
      },
      ticketOptionsMenu: {
        delete: "Delete",
        transfer: "Transfer",
        createUnidesk: "Create Unidesk Ticket",
        confirmationModal: {
          title: "Delete ticket #",
          titleFrom: "from contact ",
          message: "Attention! All ticket's related messages will be lost.",
        },
        confirmationModalForce: {
          titleUser: "You may have been blocked by this contact",
          titleGroup: "You may have been banned or the group has been deleted",
          message:
            "Do you want to close the ticket anyway?",
        },
        buttonsForce: {
          delete: "Close",
          cancel: "Cancel",
        },
        buttons: {
          delete: "Delete",
          cancel: "Cancel",
        },
      },
      vcard: {
        title: 'Send contacts to chat',
        searchContacts: 'Search Contacts',
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          send: 'Send',
          cancel: "Cancel",
        },
      },
      messageOptionsMenu: {
        delete: "Delete",
        reply: "Reply",
        edit: "Editar",
        confirmationModal: {
          title: "Delete message?",
          message: "This action cannot be reverted.",
        },
      },
      messageOptionsMultiCompanies: {
        confirm: "Yes",
        cancel: "Cancel",
        confirmationModal: {
          title: "This email is already being used",
          message: "Do you want to create a multi-company user?",
        },
      },
      multiCompanies: {
        company: "Change company",
        changeCompany: "Changing company, await..."
      },
      internalChat: {
        selectChat: "Select a user to chat with.",
        emojiPicker: "Emoji",
        messageType: "Message",
        searchUser: "Search user"
      },
      backendErrors: {
        ERR_USER_DISABLED:
          "This user does not have access to the system.",
        ERR_COMPANY_DISABLED:
          "The company is blocked by the system, contact support for more information.",
        ERR_NO_OTHER_WHATSAPP:
          "There must be at lest one default WhatsApp connection.",
        ERR_NO_DEF_WAPP_FOUND:
          "No default WhatsApp found. Check connections page.",
        ERR_WAPP_NOT_INITIALIZED:
          "This WhatsApp session is not initialized. Check connections page.",
        ERR_WAPP_CHECK_CONTACT:
          "Could not check WhatsApp contact. Check connections page.",
        ERR_WAPP_INVALID_CONTACT: "This is not a valid whatsapp number.",
        ERR_WAPP_DOWNLOAD_MEDIA:
          "Could not download media from WhatsApp. Check connections page.",
        ERR_INVALID_CREDENTIALS: "Authentication error. Please try again.",
        ERR_SENDING_WAPP_MSG:
          "Error sending WhatsApp message. Check connections page.",
        ERR_DELETE_WAPP_MSG: "Couldn't delete message from WhatsApp.",
        ERR_OTHER_OPEN_TICKET:
          "There's already an open ticket for this contact.",
        ERR_SESSION_EXPIRED: "Session expired. Please login.",
        ERR_USER_CREATION_DISABLED:
          "User creation was disabled by administrator.",
        ERR_NO_PERMISSION: "You don't have permission to access this resource.",
        ERR_DUPLICATED_CONTACT: "A contact with this number already exists.",
        ERR_NO_SETTING_FOUND: "No setting found with this ID.",
        ERR_NO_CONTACT_FOUND: "No contact found with this ID.",
        ERR_NO_SCHEDULE_FOUND: "No schedule found with this ID",
        ERR_NO_TICKET_FOUND: "No ticket found with this ID.",
        ERR_NO_USER_FOUND: "No user found with this ID.",
        ERR_TICKETS_IN_USER_FOUND: "This user has linked appointments and cannot be deleted.",
        ERR_NO_WAPP_FOUND: "No WhatsApp found with this ID.",
        ERR_CREATING_MESSAGE: "Error while creating message on database.",
        ERR_CREATING_TICKET: "Error while creating ticket on database.",
        ERR_FETCH_WAPP_MSG:
          "Error fetching the message in WhtasApp, maybe it is too old.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS:
          "This color is already in use, pick another one.",
        ERR_WAPP_GREETING_REQUIRED:
          "Greeting message is required if there is more than one queue.",
        ERR_CONN_TOKEN_EXIST:
          "There is already a connection with this token.",
        ERR_CONN_NAME_EXIST:
          "There is already a connection with this name.",
        ERR_SENDING_WAPP_MSG_GROUP_FORBIDDEN:
          "Error to send message to group",
        ERR_SENDING_WAPP_MSG_USER_FORBIDDEN:
          "Error to send message to contact",
      },
    },
  },
};

export { messages };
